import React, { FC, useEffect, useReducer, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import moment from 'moment';

import { ReactComponent as MoveDown } from 'assets/icons/move-down.svg';
import { ReactComponent as Calendar } from 'assets/icons/calendar-03.svg';

import reducer, { initialState, VIEW_ACTION } from './reducer';
import { convertMinutesIntoHoursOfDay, datetimeFormat } from 'utils/datetime';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import useNextAvailability from 'hooks/useNextAvailability';
import { SelectedTimeSlot } from 'components/DuplicateAppointment/DupplicateAppointment';
import { MINUTE_OF_A_BLOCK } from 'utils/getTimeBlockFromTImeString';
import useClinicNextAvailability from 'hooks/useClinicAvailability';

interface DayViewProps {
  availableBlocks: any[];
  selectedTimeSlot: SelectedTimeSlot;
  setSelectedTimeSlot: React.Dispatch<React.SetStateAction<SelectedTimeSlot>>;
  startDateBlock: string;
  setDate: React.Dispatch<React.SetStateAction<string>>;
  practitionerId: string | null;
  serviceId: string;
  mergePractitionerSlot: any;
}

const getHeaderBlocks = (startDateBlock: string) => {
  return Array.from({ length: 5 }).map((_, index) => {
    const currentMoment = moment(startDateBlock, 'YYYY-MM-DD').add(
      index,
      'days'
    );
    const [labelDayOfWeek, labelDayOfMonth] = currentMoment
      .format('ddd-DD')
      .split('-');
    return {
      labelDayOfWeek,
      labelDayOfMonth,
    };
  });
};

const DayView: FC<DayViewProps> = ({
  availableBlocks,
  selectedTimeSlot,
  setSelectedTimeSlot,
  startDateBlock,
  practitionerId,
  serviceId,
  setDate,
  mergePractitionerSlot,
}) => {
  const bodyContainer = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    data: practitionerNextAvailability,
    isLoading: isPractitionerNextAvailabilityLoading,
  } = useNextAvailability({
    date: startDateBlock,
    practitionerId: practitionerId!,
    serviceId,
    shouldRun: availableBlocks.length === 0 && Boolean(practitionerId),
  });

  const {
    data: clinicNextAvailability,
    isLoading: isClinicNextAvailabilityLoading,
  } = useClinicNextAvailability({
    date: startDateBlock,
    serviceId,
    shouldRun:
      mergePractitionerSlot?.availableDates?.length === 0 && !practitionerId,
  });

  useEffect(() => {
    const element = bodyContainer.current;
    if (element) {
      const isOverflow =
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth;
      setIsOverflow(isOverflow);
    }
  }, [startDateBlock, practitionerId]);

  const handleOnScroll = (e: any) => {
    const target = e.target as HTMLDivElement;
    if (target.scrollTop === 0) {
      dispatch({ type: VIEW_ACTION.SCROLL_TO_TOP });
    } else {
      dispatch({ type: VIEW_ACTION.IS_SCROLLING });
    }
    if (target.scrollHeight - target.scrollTop <= target.clientHeight) {
      dispatch({ type: VIEW_ACTION.SCROLL_TO_BOTTOM });
    }
  };

  const data = practitionerId
    ? practitionerNextAvailability
    : clinicNextAvailability;

  const handleGetNextAvailability = () => {
    setDate(data!.nextAvailabilityDate!);
    // Value === 0 means navigate by click next availability
    setSelectedTimeSlot({ date: data!.nextAvailabilityDate!, value: 0 });
  };

  const isLoading = practitionerId
    ? isPractitionerNextAvailabilityLoading && availableBlocks.length === 0
    : isClinicNextAvailabilityLoading &&
      mergePractitionerSlot.availableDates.length === 0;

  if (isLoading) {
    return (
      <div className="w-full flex justify-center mt-2">
        <LoadingSpinner className="all-child:fill-magenta" />
      </div>
    );
  }

  return (
    <div className="relative">
      <Transition
        show={isOverflow && !state.displayRemoveAnimate}
        enter="transition-opacity"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <MoveDown className="absolute bottom-0 left-[calc(50%-2.1rem)] animate-bounce z-20" />
      </Transition>
      {isOverflow && state.displayBlurBottom && (
        <div className="absolute -bottom-[1rem] bg-blur-bg-bottom w-full h-4 z-10" />
      )}
      {isOverflow && state.isScrolling && (
        <div className="absolute top-[4.5rem] bg-blur-bg-top w-full h-2 z-10" />
      )}
      <div className="grid grid-cols-dayview-layout gap-x-1.6 -mr-2.1 mb-0.9">
        {getHeaderBlocks(startDateBlock).map((block) => (
          <div
            key={block.labelDayOfMonth}
            className="flex flex-col justify-center items-center mt-0.7 text-darkest-grey"
          >
            <span>{block.labelDayOfWeek}</span>
            <span className="font-bold">{block.labelDayOfMonth}</span>
          </div>
        ))}
      </div>
      <div
        className="overflow-y-auto h-[25rem] scrollbar -mr-1.5 -mt-1 pt-1 -ml-1 pl-1"
        ref={bodyContainer}
        onScroll={handleOnScroll}
      >
        {data ? (
          <div className="flex flex-col justify-center items-center text-14 mr-2 mt-1 gap-y-1">
            <Calendar />
            <span>No availabilities</span>
            {data.nextAvailabilityDate && (
              <button
                onClick={handleGetNextAvailability}
                className="px-1.5 py-0.8 text-magenta font-bold border-magenta border-[1.5px] rounded-[10rem] hover:text-magenta-lighter hover:border-magenta-lighter"
              >
                View next availability on{' '}
                {datetimeFormat({
                  dateString: data.nextAvailabilityDate,
                  format: 'YYYY-MM-DD',
                  pattern: 'ddd, MMM DD',
                })}
              </button>
            )}
          </div>
        ) : (
          <div className="grid grid-cols-dayview-layout gap-x-1.6 mr-2 pb-2">
            {practitionerId &&
              availableBlocks.map((block: any) => (
                <div key={block.date} className="flex flex-col gap-y-1.6">
                  {block.blocks.map((time: number, index: number) => {
                    const isDisabled = block.disabledValue === time;

                    const date = selectedTimeSlot?.date;
                    const value = selectedTimeSlot?.value;

                    return (
                      <button
                        className={`shadow-tooltip w-[10rem] h-[4rem] rounded-[1rem] hover:border-magenta border ${
                          isDisabled
                            ? 'bg-[#F9F9F9] text-grey cursor-not-allowed hover:border-transparent'
                            : ''
                        } ${
                          block.date === date && value === time
                            ? 'border-magenta bg-pink-01'
                            : 'border-transparent'
                        }`}
                        key={`${time}-${index}`}
                        disabled={isDisabled}
                        onClick={() =>
                          setSelectedTimeSlot({ date: block.date, value: time })
                        }
                      >
                        {convertMinutesIntoHoursOfDay({
                          minutes: Number(time) * MINUTE_OF_A_BLOCK,
                          format: 'hh:mm A',
                        })}
                      </button>
                    );
                  })}
                </div>
              ))}
            {!practitionerId &&
              mergePractitionerSlot.availableDates.map((columnDate: string) => {
                const objValue = mergePractitionerSlot.dateItems[columnDate];
                const entries = Object.entries(objValue);

                return (
                  <div key={columnDate} className="flex flex-col gap-y-1.6">
                    {entries.map((entry: any) => {
                      const [timeSlot, { practitionerIds }] = entry;
                      const numberTimeSlot = Number(timeSlot);

                      return (
                        <button
                          key={`${numberTimeSlot}-${columnDate}`}
                          className={`shadow-tooltip w-[10rem] h-[4rem] rounded-[1rem] hover:border-magenta border ${
                            numberTimeSlot === selectedTimeSlot?.value &&
                            columnDate === selectedTimeSlot?.date
                              ? 'border-magenta bg-pink-01'
                              : 'border-transparent'
                          }`}
                          onClick={() => {
                            setSelectedTimeSlot({
                              value: numberTimeSlot,
                              date: columnDate,
                              // SELECT RANDOM PRACTITIONER
                              practitionerId: practitionerIds[0],
                            });
                          }}
                        >
                          {convertMinutesIntoHoursOfDay({
                            minutes: numberTimeSlot * MINUTE_OF_A_BLOCK,
                            format: 'hh:mm A',
                          })}
                        </button>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default DayView;
