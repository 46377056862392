import React from 'react';
import moment from 'moment';
import { Popover } from '@headlessui/react';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-02.svg';
import AppointmentDateRangePanel from './AppointmentDateRangePanel';
import useAppointmentDateRange from 'hooks/useAppointmentDateRange';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { CALENDAR_TYPE } from 'utils/getDateRangeByCalendarType';

interface IProps {
  clinicId: string | null;
  dateRange: { fromDate: string; toDate: string };
  onChangeDateRange: (params: { fromDate: string; toDate: string }) => void;
  selectedCalendarType: string;
  setSelectedCalendarType: (calendarType: string) => void;
}

const AppointmentDateRange = ({
  clinicId,
  dateRange,
  onChangeDateRange,
  selectedCalendarType,
  setSelectedCalendarType,
}: IProps) => {
  const { data, isLoading } = useAppointmentDateRange({ clinicId });

  const getDateRangeLabel = () => {
    const fromDateMoment = moment(dateRange.fromDate, 'YYYY-MM-DD');
    const toDateMoment = moment(dateRange.toDate, 'YYYY-MM-DD');

    if (
      selectedCalendarType === CALENDAR_TYPE.ALL_TIME &&
      !isLoading &&
      !data?.earliestDateTime &&
      !data?.latestDateTime
    ) {
      return 'No avaiable range';
    }

    if (fromDateMoment.isSame(toDateMoment, 'years')) {
      return `${fromDateMoment.format('MMM D')} - ${toDateMoment.format(
        'MMM D'
      )}, ${fromDateMoment.format('YYYY')}`;
    }

    return `${fromDateMoment.format('MMM DD, YYYY')} - ${toDateMoment.format(
      'MMMM DD, YYYY'
    )}`;
  };

  const onClickApply = ({
    calendarType,
    fromDate,
    toDate,
  }: {
    calendarType: string;
    fromDate: string;
    toDate: string;
  }) => {
    setSelectedCalendarType(calendarType);
    onChangeDateRange({
      fromDate,
      toDate,
    });
  };

  return (
    <div>
      <Popover className="relative">
        {({ open, close }) => {
          return (
            <>
              <Popover.Button className="focus:outline-none">
                <div
                  className={`flex flex-row justify-between items-center min-w-[32rem] rounded-[1rem] border border-lightest-grey shadow-primary py-1.3 px-1.6 max-h-[4.8rem] text-12 hover:border-magenta ${
                    open ? 'border-magenta' : ''
                  }`}
                >
                  <div>
                    <span className="mr-1 capitalize">
                      {selectedCalendarType}
                    </span>
                    <span className="">{getDateRangeLabel()}</span>
                  </div>

                  <CalendarIcon width={13} height={13} />
                </div>
              </Popover.Button>

              <Popover.Panel className="absolute flex flex-row top-[6rem] right-0 rounded-[1rem] shadow-primary bg-white py-1.2 px-0.8 z-50">
                {isLoading ? (
                  <div className="w-32 h-10 flex flex-row justify-center items-center">
                    <LoadingSpinner className="all-child:fill-magenta w-3 h-3 " />
                  </div>
                ) : (
                  <AppointmentDateRangePanel
                    clinicId={clinicId}
                    dateRange={dateRange}
                    calendarType={selectedCalendarType}
                    onClose={close}
                    onClickApply={onClickApply}
                  />
                )}
              </Popover.Panel>
            </>
          );
        }}
      </Popover>
    </div>
  );
};

export default AppointmentDateRange;
