import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import axios from 'axios';
import { chunk, debounce } from 'lodash';

import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import DayCell from './DayCell/DayCell';

import styles from './MonthView.module.css';
import axiosInstance from 'apis/axiosInstance';
import getDateRange from 'utils/getDateRange';
import getTimeSlotPerInteval from 'utils/getTimeSlotsPerInterval';
import { loadSelectedClinicId } from 'utils/storage';
import { mergePractitionersAvaiBlocks } from '../BookNowPanel/utils/mergePractitionerSlot';
import { convertToDate } from 'utils/datetime';

const getClinicAvailability = async ({
  dates,
  serviceId,
  currentDate,
  timeBlocks,
  timezone,
  cancelToken,
}: any) => {
  const clinicId = loadSelectedClinicId();
  const res = await axiosInstance.get(
    `clinics/${clinicId}/appointments/weekly-timeslots`,
    {
      params: {
        dates,
        serviceId,
        currentDate,
        timeBlocks,
        timezone,
      },
      cancelToken,
    }
  );
  return res.data[0];
};

const getPractitionerAvailability = async ({
  dates,
  serviceId,
  practitionerId,
  currentDate,
  timeBlocks,
  timezone,
  cancelToken,
}: any) => {
  const res = await axiosInstance.get(
    `practitioners/${practitionerId}/appointments/weekly-timeslots`,
    {
      params: {
        dates,
        serviceId: serviceId,
        currentDate,
        timeBlocks,
        timezone,
      },
      cancelToken,
    }
  );
  return res.data[0]?.clinic?.availableBlocks || [];
};

export interface ITimeslots {
  date: string;
  blocks: number[];
}

interface IProps {
  serviceId: string;
  practitionerId: string | null;
  date: string | null;
  selectedDate?: string;
  timeBlocks: number[];
  currentDate: string;
  onNavigateToDayView: (dateStr: string) => void;
  timezone: string;
  slotInterval: number;
}

const MonthView = (props: IProps) => {
  const {
    serviceId,
    practitionerId,
    date,
    selectedDate,
    onNavigateToDayView,
    timeBlocks,
    currentDate,
    timezone,
    slotInterval,
  } = props;

  const calendarRef = useRef<FullCalendar | null>(null);

  const [timeslots, setTimeSlots] = useState<ITimeslots[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadedDates, setIsLoadedDates] = useState<string[]>([]);
  const axiosCancelTokenRef = useRef<any>(null);

  const onClickDate = (info: any) => {
    const currentDate = moment();
    const isBefore = moment(info.dateStr).isBefore(currentDate, 'days');

    if (!isBefore) {
      onNavigateToDayView(info.dateStr);
    }
  };

  //-----------Fetch clinic and practitioner------------
  const fetchAvailabilityInDateRangeForClinic = useCallback(
    async (
      dates: string[],
      source: any
    ): Promise<{
      timeSlots: ITimeslots[];
    }> => {
      const tmp = await getClinicAvailability({
        dates,
        serviceId,
        currentDate,
        timeBlocks,
        timezone,
        cancelToken: source.token,
      });

      const doctorsByClinic = tmp?.doctors || [];

      const availBlocks = mergePractitionersAvaiBlocks(
        doctorsByClinic,
        slotInterval
      );

      const timeSlots = Object.keys(availBlocks.dateItems).reduce(
        (accum: any, date: any) => {
          accum.push({
            date,
            blocks: Object.keys(availBlocks.dateItems[date]),
          });
          return accum;
        },
        []
      );

      return {
        timeSlots,
      };
    },
    [currentDate, serviceId, slotInterval, timeBlocks, timezone]
  );

  const fetchAvailabilityInDateRangeForPractitioner = useCallback(
    async (
      practitionerId: string,
      dates: string[],
      source: any
    ): Promise<{
      timeSlots: ITimeslots[];
    }> => {
      const availBlocks = await getPractitionerAvailability({
        serviceId,
        practitionerId,
        dates,
        timeBlocks,
        currentDate,
        timezone,
        cancelToken: source.token,
      });

      const timeSlots = getTimeSlotPerInteval({
        availableBlocks: availBlocks,
        slotInterval,
      });

      return {
        timeSlots,
      };
    },
    [currentDate, serviceId, slotInterval, timeBlocks, timezone]
  );

  //----------Fetch Weekly Time Slot----------
  const fetchWeeklyTimeSlot = useCallback(
    async (startDate: Date, endDate: Date) => {
      setIsLoading(true);
      const dateStrings = getDateRange(startDate, endDate);

      const datesInFuture = dateStrings.filter((dateString) =>
        moment(dateString).isSameOrAfter(moment(), 'days')
      );

      if (axiosCancelTokenRef.current !== null) {
        axiosCancelTokenRef.current.cancel();
      }

      const CancelToken = axios.CancelToken;
      axiosCancelTokenRef.current = CancelToken.source();

      setIsLoadedDates([]);
      setTimeSlots([]);

      const tmpDatesInFuture = [...datesInFuture];

      const chunkDateInFuture = chunk(tmpDatesInFuture, 7);

      if (practitionerId) {
        chunkDateInFuture.forEach(async (futureRange) => {
          if (futureRange.length === 1) return;

          const dates = [futureRange[0], futureRange[futureRange.length - 1]];
          const { timeSlots } =
            await fetchAvailabilityInDateRangeForPractitioner(
              practitionerId,
              dates,
              axiosCancelTokenRef.current
            );

          setIsLoadedDates((state: any) => {
            return [...state, ...futureRange];
          });

          setTimeSlots((currentTimeSlots) => [
            ...currentTimeSlots,
            ...timeSlots,
          ]);
        });
      } else {
        chunkDateInFuture.forEach(async (futureRange) => {
          if (futureRange.length === 1) return;

          const dates = [futureRange[0], futureRange[futureRange.length - 1]];
          const { timeSlots } = await fetchAvailabilityInDateRangeForClinic(
            dates,
            axiosCancelTokenRef.current
          );

          setIsLoadedDates((state: any) => {
            return [...state, ...futureRange];
          });

          setTimeSlots((currentTimeSlots: any) => [
            ...currentTimeSlots,
            ...timeSlots,
          ]);
        });
      }

      setIsLoading(false);
    },
    [
      fetchAvailabilityInDateRangeForClinic,
      fetchAvailabilityInDateRangeForPractitioner,
      practitionerId,
    ]
  );

  const fetchDebounce = useMemo(() => {
    return debounce((start, end) => {
      if (fetchWeeklyTimeSlot) {
        fetchWeeklyTimeSlot(start, end);
      }
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practitionerId, serviceId]);

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      const parsedDate = date
        ? moment(date, 'YYYY-MM-DD').toDate()
        : new Date();

      calendarApi.gotoDate(parsedDate);

      const calendarData = calendarApi.getCurrentData();
      const renderDateRange = calendarData.dateProfile.renderRange;

      fetchDebounce(renderDateRange.start, renderDateRange.end);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, practitionerId]);

  return (
    <div className={styles.wrapper} id="dashboard-calendar">
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialDate={selectedDate || new Date()}
        initialView="dayGridMonth"
        contentHeight={300}
        fixedWeekCount
        showNonCurrentDates
        weekNumberCalculation="ISO"
        headerToolbar={false}
        dayHeaderClassNames="calendar-header"
        dayCellClassNames="calendar-cell"
        dayCellContent={(props) => {
          const date = moment(convertToDate(props.date)).format('YYYY-MM-DD');

          return (
            <DayCell
              {...props}
              timeslots={timeslots}
              isLoading={
                isLoading || (props.isFuture && !isLoadedDates.includes(date))
              }
            />
          );
        }}
        dateClick={onClickDate}
      />
    </div>
  );
};

export default MonthView;
