import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import { Popover } from '@headlessui/react';
import ListItem from './ListItem/ListItem';
import { Option } from '../MultipleSelect/MultipleSelect';
import useClinicService from 'hooks/useClinicService';
import { SERVICE_NAME, SPECIALTY_NAME } from 'utils/constants';
import { ReactComponent as DropdownIcon } from 'assets/icons/drop-down.svg';
interface IProps {
  control: any;
  placeholder?: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  services?: Option[];
  specialties: Option[];
}

const ServiceDropdown = (props: IProps) => {
  const { data: clinicServices } = useClinicService({
    sort: 'asc',
    sortBy: 'order',
  });

  const isHygienistSpecialtySelected = props.specialties.some(
    (specialty) => specialty.label === SPECIALTY_NAME.HYGIENIST
  );

  const activeClinicServices =
    clinicServices?.data?.filter(
      (service) =>
        service.isActive && !service.name.includes(SERVICE_NAME.EXAM_CLEANING)
    ) ?? [];

  const clinicExamCleaningService = clinicServices?.data.find(
    (service) => service.name === SERVICE_NAME.EXAM_CLEANING
  );

  const getTotalServices = () => {
    let count = activeClinicServices.length;
    if (clinicExamCleaningService && clinicExamCleaningService.isActive) {
      count += clinicExamCleaningService.childServices.length;
    }
    return count;
  };

  return (
    <Controller
      {...props}
      name="services"
      render={({ field }) => {
        const { onChange, value } = field;

        return (
          <Popover className="relative">
            <Popover.Button
              className={`w-full flex items-center h-4.8 py-1.3 pr-1.3 shadow-primary rounded-[1rem] pl-1 justify-between ${
                value.length === 0 ? 'text-grey' : ''
              }`}
            >
              {({ open }) => (
                <>
                  <span>
                    {value.length === 0
                      ? 'Select Services'
                      : `${value.length} of ${getTotalServices()} selected`}
                  </span>
                  <DropdownIcon
                    className={`${
                      open ? 'fill-[#666]' : 'fill-[#ccc]'
                    } transition-[fill]`}
                  />
                </>
              )}
            </Popover.Button>

            <Popover.Panel className="absolute w-full flex flex-row rounded-[1rem] shadow-primary bg-white z-50 h-[32rem] overflow-auto scrollbar text-14 leading-[2.1rem]">
              <ListItem
                onChange={onChange}
                value={value}
                activeClinicServices={activeClinicServices}
                clinicExamCleaningService={clinicExamCleaningService}
                isHygienistSpecialtySelected={isHygienistSpecialtySelected}
              />
            </Popover.Panel>
          </Popover>
        );
      }}
    />
  );
};

export default ServiceDropdown;
