import { FC, useEffect, useRef } from 'react';
import { SERVICE_NAME } from 'utils/constants';
import { Option } from '../../MultipleSelect/MultipleSelect';
import { IService } from 'interfaces/services';

interface ListItemProps {
  onChange: (...event: any[]) => void;
  value: any;
  activeClinicServices: IService[];
  clinicExamCleaningService: IService | undefined;
  isHygienistSpecialtySelected: boolean;
}

const normalizeExamCleaningService = (
  examCleaningService: IService | undefined
) => {
  if (!examCleaningService) {
    return {
      value: '',
      label: '',
      childServices: [],
    };
  }

  return {
    value: examCleaningService.id,
    label: examCleaningService.name,
    childServices: examCleaningService.childServices.map((childService) => ({
      value: childService.id,
      label: childService.name,
    })),
  };
};

const ListItem: FC<ListItemProps> = ({
  onChange,
  value,
  activeClinicServices,
  clinicExamCleaningService,
  isHygienistSpecialtySelected,
}) => {
  const clinicServiceOptionsExceptExamCleaning = activeClinicServices?.map(
    (service) => ({
      value: service.id,
      label: service.name,
    })
  );

  const examCleaningService = normalizeExamCleaningService(
    clinicExamCleaningService
  );

  const parentExamCleaningRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const parentExamCleaning = parentExamCleaningRef.current;
    if (!parentExamCleaning) return;

    const assignedExamCleaningServices =
      value?.filter((service: Option) =>
        service.label.includes(SERVICE_NAME.EXAM_CLEANING)
      ) ?? [];

    if (assignedExamCleaningServices.length === 0) {
      parentExamCleaning.checked = false;
      parentExamCleaning.indeterminate = false;
      return;
    }

    if (
      assignedExamCleaningServices.length ===
      examCleaningService.childServices.length
    ) {
      parentExamCleaning.checked = true;
      parentExamCleaning.indeterminate = false;
      return;
    }

    if (assignedExamCleaningServices.length > 0) {
      parentExamCleaning.checked = false;
      parentExamCleaning.indeterminate = true;
      return;
    }
  }, [examCleaningService.childServices.length, value]);

  const onParentExamCleaningChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const parentExamCleaning = e.target;

    const withoutExamCleaningServices = value.filter(
      (item: Option) => !item.label.includes(SERVICE_NAME.EXAM_CLEANING)
    );

    if (parentExamCleaning.checked) {
      parentExamCleaning.indeterminate = false;
      parentExamCleaning.checked = true;

      const updatedValue = [
        ...withoutExamCleaningServices,
        ...examCleaningService.childServices,
      ];

      onChange(updatedValue);
      return;
    }

    parentExamCleaning.indeterminate = false;
    parentExamCleaning.checked = false;

    onChange(withoutExamCleaningServices);
    return;
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    service: Option
  ) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      onChange([...value, service]);
      return;
    }

    const updatedValue = value.filter(
      (item: Option) => item.value !== service.value
    );

    onChange(updatedValue);
  };

  const isChecked = (serviceId: string) =>
    value.some((item: Option) => item.value === serviceId);

  return (
    <ul className="flex flex-col gap-y-1.6 px-2 py-2.1">
      {clinicExamCleaningService?.isActive && (
        <>
          <li className="flex items-center gap-x-1.6">
            <input
              id={examCleaningService.value}
              className="w-1.8 h-1.8"
              type="checkbox"
              ref={parentExamCleaningRef}
              onChange={onParentExamCleaningChange}
              disabled={!isHygienistSpecialtySelected}
            />
            <label
              className="text-14 leading-[2.1rem]"
              htmlFor={examCleaningService.value}
            >
              {examCleaningService.label}
            </label>
          </li>
          <ul className="pl-3.2 flex flex-col gap-y-1.6">
            {examCleaningService.childServices.map(({ label, value }) => (
              <li className="flex items-center gap-x-1.6" key={value}>
                <input
                  id={value}
                  className="w-1.8 h-1.8"
                  type="checkbox"
                  value={value}
                  checked={isChecked(value)}
                  onChange={(e) => handleOnChange(e, { label, value })}
                  disabled={!isHygienistSpecialtySelected}
                />
                <label className="text-14 leading-[2.1rem]" htmlFor={value}>
                  {label}
                </label>
              </li>
            ))}
          </ul>
        </>
      )}
      {clinicServiceOptionsExceptExamCleaning?.map(
        ({ label, value }, index) => (
          <li
            key={value}
            className={`flex items-center gap-x-1.6 ${
              index === clinicServiceOptionsExceptExamCleaning.length - 1
                ? 'pb-2.1'
                : ''
            }`}
          >
            <input
              id={value}
              className="w-1.8 h-1.8"
              type="checkbox"
              value={value}
              checked={isChecked(value)}
              onChange={(e) => handleOnChange(e, { label, value })}
            />
            <label className="text-14 leading-[2.1rem]" htmlFor={value}>
              {label}
            </label>
          </li>
        )
      )}
    </ul>
  );
};

export default ListItem;
