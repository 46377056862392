import { FC, useState } from 'react';
import CommonButton from 'components/CommonButton/CommonButton';
import DefaultModal from 'components/Modals/DefaultModal';
import useModal from 'hooks/useModal';

interface ConfirmationModalProps {
  title: string;
  description: string;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  submitBtnTitle?: string;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  title,
  description,
  onClose,
  onSubmit,
  submitBtnTitle = 'Activate',
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { setIsAllowed } = useModal({ onClose });

  const handleSubmit = async () => {
    setIsAllowed(false);
    setIsLoading(true);
    await onSubmit();

    setIsAllowed(true);
    setIsLoading(false);
  };

  return (
    <DefaultModal>
      <div className="w-48 p-2 text-darkest-grey normal-case">
        <h3 className="text-20 leading-[3rem] font-bold">{title}</h3>
        <p className="text-14 leading-[2.1rem] mt-1.6">{description}</p>
        <div className="flex justify-end gap-x-1.6 mt-2.4">
          <CommonButton
            variant="secondary"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </CommonButton>
          <CommonButton onClick={handleSubmit} isLoading={isLoading}>
            {submitBtnTitle}
          </CommonButton>
        </div>
      </div>
    </DefaultModal>
  );
};

export default ConfirmationModal;
