import { FC } from 'react';
import CommonButton from 'components/CommonButton/CommonButton';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import DefaultModal from 'components/Modals/DefaultModal';
import {
  getAppointmentEndTime,
  getAppointmentStartTime,
} from 'components/TableColumn/utils/getAppointmentTime';
import useAppointmentDetail from 'hooks/useAppointmentDetail';
import { datetimeFormat } from 'utils/datetime';
import getPractitionerName from 'utils/getPractitionerName';
import { SPECIALTY_NAME } from 'utils/constants';
import useModal from 'hooks/useModal';
import { useForm } from 'react-hook-form';
import axiosInstance from 'apis/axiosInstance';
import useMatchMutate from 'hooks/useMatchMutate';
import { renderToast } from 'components/Toast/Toast';

interface AcceptAppointmentModalProps {
  appointmentId: string;
  onClose: () => void;
}

const AcceptAppointmentModal: FC<AcceptAppointmentModalProps> = ({
  appointmentId,
  onClose,
}) => {
  const { data, isLoading } = useAppointmentDetail(
    appointmentId,
    !!appointmentId
  );

  const matchMutate = useMatchMutate();

  const { setIsAllowed } = useModal({ onClose, isLoading });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  if (isLoading) {
    return <LoadingScreen />;
  }

  const onSubmit = async () => {
    setIsAllowed(false);
    const payload = [
      {
        appointmentId: data.id,
        status: 'accepted',
      },
    ];
    try {
      await axiosInstance.patch('/appointments', payload);
      await matchMutate(/\/appointments\?[\s\S]+/);
      renderToast({
        type: 'success',
        message: `Successfully accepted appointment`,
      });
      onClose();
    } catch (error) {
      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
    setIsAllowed(true);
  };

  return (
    <DefaultModal>
      <div className="max-w-[48rem] min-h-[32rem] rounded-[1rem] border-light-grey bg-white p-[2rem]">
        <div className="text-14 leading-[2.1rem] flex flex-col gap-y-2.4">
          <section className="flex flex-col">
            <h3 className="text-20 font-bold leading-[3rem]">
              Does this look correct?
            </h3>
            <div className="mt-1.6">
              <div>
                Appointment Date:{' '}
                <span className="font-bold">
                  {datetimeFormat({
                    dateString: data.appointmentDate,
                    format: 'YYYY-MM-DD',
                    pattern: 'ddd, MMM D, YYYY',
                  })}
                </span>
              </div>
              <div>
                Time:{' '}
                <span className="font-bold">
                  {' '}
                  {getAppointmentStartTime(data)}
                  {' - '}
                  {getAppointmentEndTime(data)}
                </span>
              </div>
              <div>
                Service: <span className="font-bold">{data.service?.name}</span>
              </div>
              {data.linkedAppointment ? (
                <>
                  <div>
                    {data.doctor.specialist === SPECIALTY_NAME.BOTH
                      ? SPECIALTY_NAME.DENTIST
                      : data.doctor.specialist}
                    :{' '}
                    <span className="font-bold">
                      {getPractitionerName(data.doctor)}
                    </span>
                  </div>
                  <div>
                    Doctor:{' '}
                    <span className="font-bold">
                      {getPractitionerName(data.linkedAppointment.doctor)}
                    </span>
                  </div>
                </>
              ) : (
                <div>
                  Doctor:{' '}
                  <span className="font-bold">
                    {getPractitionerName(data.doctor)}
                  </span>
                </div>
              )}

              <p className="mt-2">
                If you have made any changes in your practice software, please
                wait for those changes to show in FirstIn. This usually takes 10
                to 15 minutes.
              </p>
            </div>
          </section>
          <section className="flex gap-x-1.6 justify-end">
            <CommonButton
              variant="secondary"
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </CommonButton>
            <CommonButton
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
            >
              Yes, accept
            </CommonButton>
          </section>
        </div>
      </div>
    </DefaultModal>
  );
};

export default AcceptAppointmentModal;
