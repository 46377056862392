import CommonButton from 'components/CommonButton/CommonButton';
import usePMS from 'hooks/usePMS';

const InstallationSection = () => {
  const { data, isLoading } = usePMS();

  const handleClickDownload = () => {
    if (!data) return;
    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', data.link);
    linkElement.setAttribute('download', '');

    linkElement.click();
    linkElement.remove();
  };

  return (
    <div className="pb-2 border-b border-light-grey">
      <div className="flex justify-between">
        <div className="basis-[45rem]">
          <div className="flex gap-x-0.9">
            <h3 className="font-bold mt-[0.55rem]">
              Download the installation file for FirstIn integration
            </h3>
          </div>
          <p className="mt-[2.55rem]">
            Download the FirstIn installation file to integrate your practice
            software with FirstIn. Contact your admin or FirstIn for support.
          </p>
        </div>
        <div className="flex flex-col items-end">
          <CommonButton
            variant="secondary"
            className="relative !min-h-[3.2rem]"
            onClick={handleClickDownload}
            disabled={isLoading}
          >
            <div className="flex">
              <span>Download</span>
            </div>
          </CommonButton>
        </div>
      </div>
    </div>
  );
};

export default InstallationSection;
