import { FC, useState } from 'react';
import { Popover } from '@headlessui/react';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { APPOINTMENT_STATUS, ERROR_REASON } from 'utils/constants';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import useMatchMutate from 'hooks/useMatchMutate';
import DuplicateAppointment from 'components/DuplicateAppointment/DupplicateAppointment';
import PartialPatient from 'components/PartialPatient/PartialPatient';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { IAppointmentStatus } from 'hooks/useAppointment';
import { getErrorReason } from 'utils/common';
import AcceptAppointmentModal from 'components/AcceptAppointmentModal/AcceptAppointmentModal';

interface ThreeDotsOptionProps {
  appointment: any;
  status: IAppointmentStatus;
  isAppointmentInThePast: boolean;
}

const {
  DUPLICATE_BOOKING,
  OUT_OF_OFFICE_HOURS,
  OUT_OF_PRACTITIONER_HOURS,
  PARTIAL_PATIENT_MATCH,
  GENERAL_ERROR,
  PATIENT_NEED_ADD_TO_FAMILY,
  WRITE_FAIL,
  UNFOUNDED_AVAILABLE_PRACTITIONER,
} = ERROR_REASON;

const ThreeDotsOption: FC<ThreeDotsOptionProps> = ({
  appointment,
  status,
  isAppointmentInThePast,
}) => {
  const errorReason = appointment && getErrorReason(appointment);

  const matchMutate = useMatchMutate();

  const [confirmModalMode, setConfirmModalMode] = useState('');
  const [errorModal, setErrorModal] = useState('');
  const [isChecking, setIsChecking] = useState(false);

  const getOptions = () => {
    if (isAppointmentInThePast) {
      return ['Accept'];
    }

    if (
      errorReason === OUT_OF_OFFICE_HOURS ||
      errorReason === OUT_OF_PRACTITIONER_HOURS ||
      errorReason === UNFOUNDED_AVAILABLE_PRACTITIONER ||
      errorReason === PATIENT_NEED_ADD_TO_FAMILY
    ) {
      return ['Accept', 'Modify Appointment', 'Reject'];
    }

    const options = ['Reject'];

    if (!errorReason && status !== 'accepted') {
      options.unshift('Modify Appointment');
      options.unshift('Accept');
    }

    if (errorReason === DUPLICATE_BOOKING) {
      options.unshift('Modify Appointment');
    }
    if (errorReason === PARTIAL_PATIENT_MATCH) {
      options.unshift('Select matching patient');
    }
    if ([GENERAL_ERROR, WRITE_FAIL].includes(errorReason)) {
      options.unshift('Accept');
    }

    return options;
  };

  const checkedIfErrorIsTheSame = async () => {
    setIsChecking(true);
    let isErrorTheSame = true;
    try {
      const response = await axiosInstance.get(
        `/appointments/${appointment.id}`
      );
      const errorReason = getErrorReason(response.data);
      if (!errorReason) {
        isErrorTheSame = false;
        await matchMutate(/\/appointments\?[\s\S]+/);
        handleAppointmentAlreadyResolved(false);
      }
    } catch (error) {
      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
    setIsChecking(false);
    return isErrorTheSame;
  };

  const handleClickOption = async (option: string) => {
    if (option === 'Accept' || option === 'Reject') {
      return setConfirmModalMode(option);
    }

    const isNormalApptReschedule =
      !appointment.errorReason &&
      appointment.status === APPOINTMENT_STATUS.NEW.KEY;

    // SKIP ASYNC CHECK IF APPT IS NORMAL RESCHEDULE
    if (!isNormalApptReschedule) {
      const isErrorTheSame = await checkedIfErrorIsTheSame();
      if (!isErrorTheSame) return;
    }

    if (option === 'Modify Appointment') {
      return setErrorModal('timeslot');
    }
    if (option === 'Select matching patient') {
      return setErrorModal('patient');
    }
  };

  const handleSubmitConfirmation = async () => {
    try {
      const updatedStatus =
        confirmModalMode === 'Accept' ? 'accepted' : 'cancelled';

      const toastMessage =
        confirmModalMode === 'Accept' ? 'accepted' : 'rejected';

      await axiosInstance.patch('/appointments', [
        {
          appointmentId: appointment.id,
          status: updatedStatus,
        },
      ]);
      await matchMutate(/\/appointments\?[\s\S]+/);
      renderToast({
        type: 'success',
        message: `Successfully ${toastMessage} appointment`,
      });
      setConfirmModalMode('');
    } catch (error) {
      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
  };

  const handleAppointmentAlreadyResolved = (isDuplicateResolved: boolean) => {
    const message = (
      <>
        This{' '}
        {isDuplicateResolved
          ? 'duplicate appointment'
          : 'partial patient match'}{' '}
        error for <strong>{appointment.patient.patientName}</strong> has been
        resolved. Please accept or reject the appointment below.
      </>
    );

    renderToast({ message, type: 'success' });
  };

  const isOptionDisabled = (option: string) =>
    errorReason === PATIENT_NEED_ADD_TO_FAMILY &&
    option === 'Modify Appointment';

  return (
    <>
      {isChecking && <LoadingScreen />}
      {errorModal === 'timeslot' && (
        <DuplicateAppointment
          id={appointment.id}
          mode={errorReason}
          onClose={() => setErrorModal('')}
          onAppointmentAlreadyResolved={() =>
            handleAppointmentAlreadyResolved(true)
          }
        />
      )}
      {errorModal === 'patient' && (
        <PartialPatient
          id={appointment.id}
          onClose={() => setErrorModal('')}
          onAppointmentAlreadyResolved={() =>
            handleAppointmentAlreadyResolved(false)
          }
        />
      )}
      {confirmModalMode ? (
        confirmModalMode === 'Accept' ? (
          <AcceptAppointmentModal
            appointmentId={appointment.id}
            onClose={() => setConfirmModalMode('')}
          />
        ) : (
          <ConfirmationModal
            title={`Reject appointment`}
            description={`Are you sure you want to reject this appointment?`}
            onClose={() => setConfirmModalMode('')}
            onSubmit={handleSubmitConfirmation}
            submitBtnTitle={`Yes, reject`}
          />
        )
      ) : null}
      <Popover className="relative hidden group-hover:block">
        <Popover.Button className="flex items-center disabled:cursor-not-allowed disabled:opacity-30">
          <MoreIcon className="h-2.2" />
        </Popover.Button>
        <Popover.Panel
          className={`flex flex-col gap-y-1.6 absolute py-2 right-0 z-10 bg-white shadow-primary rounded-[0.8rem] mt-0.6 ${
            [GENERAL_ERROR, WRITE_FAIL].includes(errorReason) ||
            isAppointmentInThePast
              ? 'w-16.5'
              : 'w-21'
          }`}
        >
          {getOptions().map((option) => (
            <Popover.Button
              key={option}
              className="w-full px-2 hover:bg-opacity-10 hover:text-magenta text-left 
             disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:text-darkest-grey"
              onClick={() => handleClickOption(option)}
              disabled={isOptionDisabled(option)}
            >
              {option}
            </Popover.Button>
          ))}
        </Popover.Panel>
      </Popover>
    </>
  );
};

export default ThreeDotsOption;
