import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { isEqual } from 'lodash';
import useOperatory from 'hooks/useOperatory';

import OperatoryDropdown from 'pages/PractitionerPage/MainSection/PractitionerProfile/OperatoryDropdown/OperatoryDropdown';
import CommonButton from 'components/CommonButton/CommonButton';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { Option } from 'pages/PractitionerPage/MainSection/PractitionerProfile/MultipleSelect/MultipleSelect';
import { renderToast } from 'components/Toast/Toast';
import axiosInstance from 'apis/axiosInstance';
import { BAD_REQUEST } from 'utils/constants/statusCode';

const HygieneChairSection = () => {
  const { data, isLoading, mutate } = useOperatory();

  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting, errors, defaultValues },
    reset,
    watch,
  } = useForm<{
    operatories: Option[];
  }>({
    mode: 'all',
    defaultValues: {
      operatories: [],
    },
  });

  const isDataModified = !isEqual(
    defaultValues?.operatories,
    watch('operatories')
  );

  const isHygieneChairSetup = data?.some((item) => item.isHygieneChair);

  useEffect(() => {
    const getInitialValue = () => {
      if (isLoading) return [];

      const hygieneChairOperatories = data!.filter(
        (operatory) => operatory.isHygieneChair
      );

      const defaultValue = hygieneChairOperatories.map((operatory) => ({
        label: operatory.name,
        value: operatory.id,
      }));
      return defaultValue;
    };

    reset({ operatories: getInitialValue() });
  }, [data, isLoading, reset]);

  const onUpdateClincOpHygiene = async ({
    operatories,
  }: {
    operatories: Option[];
  }) => {
    const hygieneOperatoryIds = operatories.map((operatory) => operatory.value);

    try {
      await axiosInstance.patch(`clinics/operatories/hygiene-chairs`, {
        hygieneOperatoryIds,
      });

      await mutate();

      renderToast({
        type: 'success',
        message: 'Updated hygiene chairs successfully',
      });
    } catch (error: any) {
      if (error.response?.status === BAD_REQUEST) {
        return renderToast({
          message: error.response?.data.message,
          type: 'error',
        });
      }

      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
  };

  if (isLoading) {
    return (
      <div className="my-2.4 bg-lightest-grey shadow-elevation-07 h-[119px]" />
    );
  }

  return (
    <div className="my-2.4 bg-lightest-grey shadow-elevation-07 p-2 h-[119px]">
      {!isHygieneChairSetup && (
        <p>
          To set up Exam & Cleaning service, please select at least one hygiene
          chair
        </p>
      )}
      <div className="flex items-center mt-1">
        <p className="font-bold">
          Hygiene Chairs{' '}
          <span
            className={
              errors.operatories ? 'text-secondary-red' : 'text-mid-grey'
            }
          >
            *
          </span>
        </p>
        <div className="basis-[23rem] ml-3">
          <OperatoryDropdown
            control={control}
            rules={{ required: true, min: 1 }}
          />
        </div>

        {isDataModified && (
          <CommonButton
            className="!min-w-[9rem] !min-h-[3.2rem] ml-1.6"
            onClick={handleSubmit(onUpdateClincOpHygiene)}
            disabled={!isValid}
            isLoading={isSubmitting}
          >
            Save
          </CommonButton>
        )}
      </div>
      {errors.operatories && (
        <div className={`ml-13.7 flex items-center gap-x-1 mt-0.8`}>
          <WarningIcon />
          <span className="text-12 text-red-01">This field is required</span>
        </div>
      )}
    </div>
  );
};

export default HygieneChairSection;
