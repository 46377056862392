import { FC, useEffect, useState } from 'react';
import { Listbox } from '@headlessui/react';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';
import useClinicService from 'hooks/useClinicService';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { IMapPractitionerColor } from 'pages/SchedulePage/utils';
import { convertDurationForFE } from 'utils/convertDuration';
import { SERVICE_NAME, SPECIALTY_NAME } from 'utils/constants';
import { IDebugModeDropdown } from '../..';
import { filterPractitionersBySelectedService } from '../../utils';
import getPractitionerName from 'utils/getPractitionerName';
import { IService } from 'interfaces/services';

interface DebugViewDropdownProps {
  practitioners: IMapPractitionerColor[];
  onChangeDebugModeDropdown: (
    dropdownOption: IDebugModeDropdown | null
  ) => void;
}

const ALL_PRACTITIONER_OPTIONS = { id: 'all', name: 'All practitioners' };

const DebugViewDropdown: FC<DebugViewDropdownProps> = ({
  practitioners: initialPractitioners,
  onChangeDebugModeDropdown,
}) => {
  const { data: clinicServices, isLoading } = useClinicService({
    sort: 'asc',
    sortBy: 'order',
  });

  const activeClinicServices = clinicServices?.data
    .filter((service) => service.isActive)
    .reduce((accum: IService[], currentService) => {
      if (currentService.childServices.length !== 0) {
        accum = accum.concat(currentService.childServices);
      } else {
        accum.push(currentService);
      }

      return accum;
    }, []);

  const [practitioners, setPractitioners] = useState([
    ALL_PRACTITIONER_OPTIONS,
    ...initialPractitioners,
  ]);

  const [selectedService, setSelectedService] = useState<IService | null>(null);

  const [selectedPractitioner, setSelectedPractitioner] = useState<
    IMapPractitionerColor | null | typeof ALL_PRACTITIONER_OPTIONS
  >(ALL_PRACTITIONER_OPTIONS);

  useEffect(() => {
    if (selectedService === null) {
      onChangeDebugModeDropdown(null);
      return;
    }

    let tmpPractitioners: any = [];

    if (selectedPractitioner) {
      if (selectedPractitioner.id === ALL_PRACTITIONER_OPTIONS.id) {
        tmpPractitioners = practitioners
          .filter(
            (practitioner) => practitioner.id !== ALL_PRACTITIONER_OPTIONS.id
          )
          .map((practitioner) => ({
            id: practitioner.id,
            colorCode: (practitioner as IMapPractitionerColor).colorCode,
          }));
      } else {
        tmpPractitioners = [
          {
            id: selectedPractitioner.id,
            colorCode: (selectedPractitioner as IMapPractitionerColor)
              .colorCode,
          },
        ];
      }
    }

    onChangeDebugModeDropdown({
      service: {
        id: selectedService.id,
        name: selectedService.name,
        isSplitScheduling: selectedService.isSplitScheduling || false,
        duration: selectedService.duration,
        hygienistDuration: selectedService.hygienistDuration,
        doctorDuration: selectedService.doctorDuration,
      },
      practitioners: tmpPractitioners,
    });
  }, [
    initialPractitioners,
    onChangeDebugModeDropdown,
    practitioners,
    selectedPractitioner,
    selectedService,
  ]);

  const onChangeService = (service: IService) => {
    const isExamAndCleaningSvc = service.name.startsWith(
      SERVICE_NAME.EXAM_CLEANING
    );

    let updatedPractitioners = [];

    if (isExamAndCleaningSvc) {
      updatedPractitioners = filterPractitionersBySelectedService({
        selectedService: {
          id: service.id,
          duration: service.duration,
          name: service.name,
          isSplitScheduling: service.isSplitScheduling || false,
        },
        practitioners: initialPractitioners,
      }).filter((item) => item.specialist === SPECIALTY_NAME.HYGIENIST);
    } else {
      updatedPractitioners = initialPractitioners.filter((practitioner) => {
        return practitioner.services?.find((s) => s.id === service.id) || false;
      });
    }

    setSelectedPractitioner(
      updatedPractitioners.length === 0 ? null : ALL_PRACTITIONER_OPTIONS
    );

    setPractitioners(
      updatedPractitioners.length > 0
        ? [ALL_PRACTITIONER_OPTIONS, ...updatedPractitioners]
        : updatedPractitioners
    );
    setSelectedService(service);
  };

  const onChangePractitioner = (practitioner: IMapPractitionerColor) => {
    setSelectedPractitioner(practitioner);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="flex gap-x-1.6">
      <Listbox value={selectedService} onChange={onChangeService}>
        {({ open }) => {
          return (
            <div className="relative">
              <Listbox.Button
                className={`relative w-[18rem] h-[2.4rem] cursor-pointer rounded-[0.8rem] bg-whiteborder-light-grey pl-0.8 text-left shadow-primary border border-transparent hover:border-magenta  hover:shadow-elevation-10 outline-none ${
                  open ? '!border-magenta !shadow-elevation-10' : ''
                }`}
              >
                <div className="flex truncate text-darkest-grey text-12 leading-[1.8rem]">
                  {selectedService ? (
                    <>
                      <div className="w-[40%] truncate">
                        {selectedService.name}
                      </div>
                      <span className="mr-0.5"> | </span>
                      <div>
                        {convertDurationForFE(selectedService.duration)} minutes
                      </div>
                    </>
                  ) : (
                    <span>Service Name | Duration</span>
                  )}
                </div>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[0.8rem]">
                  <DropdownIcon className="w-1 h-1" />
                </span>
              </Listbox.Button>
              <Listbox.Options className="absolute mt-1 w-full overflow-auto rounded-[1rem] bg-white py-1.5 text-base shadow-primary flex flex-col gap-y-1.5 z-[9999] max-h-[50rem] scrollbar">
                {activeClinicServices?.map((service) => (
                  <Listbox.Option
                    key={service.id}
                    className="relative cursor-default select-none pl-1.6"
                    value={service}
                  >
                    <span
                      className={`block cursor-pointer hover:text-magenta ${
                        service.id === selectedService?.id ? 'text-magenta' : ''
                      }`}
                    >
                      {service.name} | {convertDurationForFE(service.duration)}{' '}
                      minutes
                    </span>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          );
        }}
      </Listbox>
      <Listbox
        value={selectedPractitioner}
        onChange={onChangePractitioner}
        disabled={!selectedPractitioner || !selectedService}
      >
        {({ open }) => {
          return (
            <div className="relative">
              <Listbox.Button
                className={`relative w-[18rem] h-[2.4rem] cursor-pointer rounded-[0.8rem] bg-whiteborder-light-grey pl-0.8 text-left shadow-primary border border-transparent hover:border-magenta  hover:shadow-elevation-10 outline-none disabled:bg-mid-grey disabled:border-none ${
                  open ? '!border-magenta !shadow-elevation-10' : ''
                }`}
              >
                <span className="block w-[90%] truncate text-darkest-grey text-12 leading-[1.8rem]">
                  {selectedPractitioner
                    ? selectedPractitioner.id === ALL_PRACTITIONER_OPTIONS.id
                      ? ALL_PRACTITIONER_OPTIONS.name
                      : getPractitionerName(
                          selectedPractitioner as IMapPractitionerColor
                        )
                    : 'No practitioner available'}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[0.8rem]">
                  <DropdownIcon className="w-1 h-1" />
                </span>
              </Listbox.Button>
              <Listbox.Options className="absolute mt-1 w-full overflow-auto rounded-[1rem] bg-white py-1.5 text-base shadow-primary flex flex-col gap-y-1.5 z-[9999]">
                {practitioners.map((practitioner) => (
                  <Listbox.Option
                    key={practitioner.id}
                    className="relative cursor-default select-none pl-1.6"
                    value={practitioner}
                  >
                    <span
                      className={`block cursor-pointer hover:text-magenta ${
                        practitioner.id === selectedPractitioner?.id
                          ? 'text-magenta'
                          : ''
                      }`}
                    >
                      {practitioner.id === ALL_PRACTITIONER_OPTIONS.id
                        ? ALL_PRACTITIONER_OPTIONS.name
                        : getPractitionerName(
                            practitioner as IMapPractitionerColor
                          )}
                    </span>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          );
        }}
      </Listbox>
    </div>
  );
};

export default DebugViewDropdown;
