import { FC } from 'react';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import Overlay from 'components/Overlay/Overlay';
import { createPortal } from 'react-dom';

interface LoadingScreenProps {}

const LoadingScreen: FC<LoadingScreenProps> = () => {
  return createPortal(
    <Overlay>
      <div className="flex items-center justify-center w-full h-full">
        <div className="flex flex-col gap-y-1.1 items-center justify-center w-[14.5rem] h-[14.5rem] bg-white rounded-[1rem] border-grey">
          <LoadingSpinner className="all-child:fill-magenta w-4 h-4" />
          <span className="text-14 leading-[2.1rem] text-darkest-grey">
            Loading ...
          </span>
        </div>
      </div>
    </Overlay>,
    document.body
  );
};

export default LoadingScreen;
