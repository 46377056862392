import React from 'react';

import AvatarPlaceHolder from 'assets/images/no-avatar.svg';

import { IPractitioner } from 'interfaces/practitioners';
import ReactTooltip from 'react-tooltip';
import Status from 'components/Status/Status';
import { PRACTITIONER_FILTER_OPTIONS } from 'utils/constants';
import getPractitionerName from 'utils/getPractitionerName';
import getPractitionerSpecialists from 'utils/getPractitionerSpecialists';

const { AVAILABLE, UNAVAILABLE } = PRACTITIONER_FILTER_OPTIONS;

interface IProps {
  practitioner: IPractitioner;
  onClick: () => void;
  selectedPractitionerId?: string;
  isCollapsed: boolean;
}

const Item = ({
  practitioner,
  onClick,
  selectedPractitionerId,
  isCollapsed,
}: IProps) => {
  const { avatar, isActive, id } = practitioner;
  let displayedSpecialties = getPractitionerSpecialists(practitioner);

  const onImageError = (e: any) => {
    e.target.src = `${AvatarPlaceHolder}`;
  };

  return (
    <div
      className={`h-10 flex items-center pl-1.9 border-b border-light-grey cursor-pointer ${
        selectedPractitionerId === id ? 'bg-lightPink' : 'bg-white'
      }`}
      onClick={onClick}
    >
      <img
        data-for={id}
        data-tip=""
        src={avatar || AvatarPlaceHolder}
        alt="no-avatar"
        onError={onImageError}
        className="rounded-full object-cover w-6 h-6 aspect-square"
        width={60}
        height={60}
      />
      {isCollapsed ? (
        <ReactTooltip
          id={id}
          place="bottom"
          effect="solid"
          className="!bg-white !border-light-grey !rounded-[0.8rem] !w-auto !h-auto !px-[1rem] !py-[0.8rem] !opacity-100 z-[9999] flex justify-center items-center !min-w-[11.5rem] shadow-primary min-h-[6rem] before:hidden after:hidden"
        >
          <span className="text-11 leading-[1.8rem] text-darkest-grey flex items-center font-normal mb-0.2">
            {getPractitionerName(practitioner)}
          </span>
          <Status isActive={isActive}>
            {isActive ? AVAILABLE : UNAVAILABLE}
          </Status>
        </ReactTooltip>
      ) : (
        <div className="ml-2.9 max-w-[200px]">
          <p className="text-16 leading-[2.4rem] font-bold truncate">
            {getPractitionerName(practitioner)}
          </p>
          <p className="text-14 leading-21 truncate">{displayedSpecialties}</p>
          <div className="mt-0.4">
            <Status isActive={isActive}>
              {isActive ? AVAILABLE : UNAVAILABLE}
            </Status>
          </div>
        </div>
      )}
    </div>
  );
};

export default Item;
