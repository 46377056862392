import { mapKeys } from 'lodash';
import getIntervalStep from 'utils/getIntervalStep';

// TODO: REFACTOR FOR PERFORMANCE
export const mergePractitionersAvaiBlocks = (
  practitioners: {
    id: string;
    availableBlocks: {
      date: string;
      blocks: number[];
    }[];
  }[],
  slotInterval?: number
) => {
  const res = {} as {
    [key: string]: {
      [key: string]: {
        practitionerIds: string[];
      };
    };
  };
  if (!practitioners)
    return {
      dateItems: res,
      availableDates: Object.keys(res),
    };
  /****************************************************/
  // Created: Phien Nguyen
  // This funtion is to group practitioner's availability by block number in each date
  // Input: Array of practitioners [{id: string, availableBlocks: [date: string, blocks: number[]]}]
  // Output: Object of dateItems {[date: string]: {[block: number]: {practitionerIds: string[]}}}
  /****************************************************/
  practitioners.forEach((practitioner) => {
    practitioner.availableBlocks.forEach((date) => {
      if (res[date.date]) {
        date.blocks.forEach((block) => {
          if (res[date.date][block]) {
            res[date.date][block].practitionerIds.push(practitioner.id);
          } else {
            res[date.date][block] = {
              practitionerIds: [practitioner.id],
            };
          }
        });
      } else {
        res[date.date] = {};
        date.blocks.forEach((block) => {
          res[date.date][block] = {
            practitionerIds: [practitioner.id],
          };
        });
      }
    });
  });
  const data = {
    dateItems: res,
    availableDates: Object.keys(res),
  };

  const step = getIntervalStep(slotInterval);

  mapKeys(data.dateItems, (value, key) => {
    const numberOfSlots = Object.keys(value);
    let updatedSlots: string[] = [];
    for (let index = 0; index < numberOfSlots.length; index++) {
      if (index === 0) {
        updatedSlots.push(numberOfSlots[index]);
        continue;
      }
      const lastUpdatedSlots = updatedSlots[updatedSlots.length - 1];
      if (parseInt(numberOfSlots[index]) > parseInt(lastUpdatedSlots) + step) {
        updatedSlots.push(numberOfSlots[index]);
        continue;
      }
    }
    mapKeys(value, (_, key2) => {
      if (!updatedSlots.includes(key2)) {
        delete data.dateItems[key][key2];
      }
    });
  });

  return data;
};
