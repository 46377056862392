import { FC, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
} from 'react-hook-form';
import { Popover } from '@headlessui/react';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import AvatarPlaceHolder from 'assets/images/no-avatar.svg';
import getPractitionerName from 'utils/getPractitionerName';
import { Specialty } from 'interfaces/specialty';
import getPractitionerSpecialists from 'utils/getPractitionerSpecialists';

interface AssignedDoctorSectionProps {
  control: any;
  doctors: {
    id: string;
    avatar: string;
    firstName: string;
    name: string;
    title: string;
    specialist: string;
    specialties: Specialty[];
  }[];
}

const AssignedDoctorSection: FC<AssignedDoctorSectionProps> = ({
  control,
  doctors,
}) => {
  const selectAllElementRef = useRef<HTMLInputElement | null>(null);

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedId: string,
    { value, onChange }: ControllerRenderProps<FieldValues, 'doctorIds'>
  ) => {
    const selectAllElement = selectAllElementRef.current;
    if (!selectAllElement) return;
    if (e.currentTarget.checked) {
      const updatedValue = [...value, selectedId];
      onChange(updatedValue);

      const isAllSelected = updatedValue.length === doctors.length;
      selectAllElement.indeterminate = !isAllSelected;
      selectAllElement.checked = isAllSelected;
      return;
    }
    const updatedValue = value.filter((id: string) => id !== selectedId);

    const isAllDeselected = updatedValue.length === 0;

    selectAllElement.checked = isAllDeselected;
    selectAllElement.indeterminate = !isAllDeselected;

    onChange(updatedValue);
  };

  const handleOnSelectAll = (
    e: React.ChangeEvent<HTMLInputElement>,
    { onChange }: ControllerRenderProps<FieldValues, 'doctorIds'>
  ) => {
    if (e.currentTarget.checked) {
      const updatedValue = doctors.map((doctor) => doctor.id);
      onChange(updatedValue);
      return;
    }
    onChange([]);
  };

  return (
    <div className="flex flex-col gap-y-0.8">
      <div className="flex items-center gap-x-1">
        <label className="font-bold text-16 leading-[2.4rem]" htmlFor="">
          Doctors assigned to Split Scheduling
        </label>
        <button data-for="doctor-split-scheduling" data-tip="">
          <InfoIcon className="w-1.6 h-1.6 all-child:fill-mid-grey" />
        </button>
        <ReactTooltip
          id="doctor-split-scheduling"
          effect="solid"
          place="bottom"
          className="!bg-white !rounded-[0.8rem] !w-[18.5rem] !h-[auto] z-[9999] flex justify-center items-center !opacity-100 shadow-elevation-07 after:hidden before:hidden !px-[10px] !py-[4px]"
        >
          <span className="text-11 text-darkest-grey flex items-center font-normal">
            Assigning practitioners to specific recare services.
          </span>
        </ReactTooltip>
      </div>
      <Controller
        control={control}
        name={'doctorIds'}
        rules={{
          validate: (value) => {
            if (value.length === 0) {
              return 'This field is required';
            }
          },
        }}
        render={({ field, fieldState: { error } }) => {
          const { value } = field;
          return (
            <div className="relative">
              <Popover>
                <Popover.Button
                  className={`w-full h-[4.8rem] pl-1.6 cursor-pointer rounded-[1rem] bg-white border-[0.5px] border-light-grey text-left outline-none ${
                    error?.message
                      ? 'shadow-input-error border-[#f44336] hover:border-[#f44336] hover:shadow-input-error focus:border-[#f44336] focus:shadow-input-error'
                      : 'shadow-primary border-lightest-grey hover:border-magenta hover:shadow-input-active focus:border-magenta focus:shadow-input-active'
                  } ${
                    value.length === 0 ? 'text-mid-grey' : 'text-darkest-grey'
                  }`}
                >
                  {value.length === 0
                    ? 'Select Doctors'
                    : `${value.length} of ${doctors?.length} selected`}
                </Popover.Button>
                {error?.message && (
                  <div className={`flex items-center gap-x-1 mt-1`}>
                    <WarningIcon />
                    <span className="text-12 text-red-01">{error.message}</span>
                  </div>
                )}
                <Popover.Panel className="absolute w-full top-4.8 rounded-[0.8rem] bg-white text-base shadow-primary flex flex-col gap-y-1.6 z-20 p-2 max-h-[28rem] overflow-auto scrollbar">
                  <div className="flex gap-x-1.6 items-center ">
                    <input
                      type="checkbox"
                      className="w-1.8 h-1.8"
                      id="selectAllPractitioner"
                      ref={selectAllElementRef}
                      onChange={(e) => handleOnSelectAll(e, field)}
                      checked={value?.length === doctors?.length}
                    />
                    <label
                      className="flex gap-x-1.6 cursor-pointer font-bold"
                      htmlFor="selectAllPractitioner"
                    >
                      Select All
                    </label>
                  </div>
                  {doctors?.map((doctor) => (
                    <div
                      key={doctor.id}
                      className="flex gap-x-1.6 items-center"
                    >
                      <input
                        type="checkbox"
                        className="w-1.8 h-1.8"
                        id={doctor.id}
                        onChange={(e) => handleOnChange(e, doctor.id, field)}
                        checked={value.includes(doctor.id)}
                      />

                      <label
                        className="flex gap-x-1.6 cursor-pointer"
                        htmlFor={doctor.id}
                      >
                        <img
                          src={doctor.avatar || AvatarPlaceHolder}
                          alt="no-avatar"
                          className="rounded-full object-cover w-4.8 h-4.8 aspect-square"
                          width={48}
                          height={48}
                        />
                        <div className="flex flex-col">
                          <span className="truncate font-bold">
                            {getPractitionerName(doctor)}
                          </span>
                          <span className="w-[30rem] truncate">
                            {getPractitionerSpecialists(doctor)}
                          </span>
                        </div>
                      </label>
                    </div>
                  ))}
                </Popover.Panel>
              </Popover>
            </div>
          );
        }}
      />
    </div>
  );
};

export default AssignedDoctorSection;
