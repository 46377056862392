import { useState } from 'react';
import ClinicFilter from './ClinicFilter/ClinicFilter';
import Table from './Table/Table';
import AppointmentStatus from 'components/AppontmentStatus/AppointmentStatus';
import { IAppointmentStatus } from 'hooks/useAppointment';
import {
  CALENDAR_TYPE,
  getDateRangeByCalendarType,
} from 'utils/getDateRangeByCalendarType';
import AppointmentDateRange from 'components/AppointmentDateRange/AppointmentDateRange';
import axiosInstance from 'apis/axiosInstance';
import moment from 'moment';

const defaultTableSortState = {
  sortBy: 'appointmentDate',
  sort: 'desc' as const,
};

const AdminAppointmentPage = () => {
  const [selectedClinicId, setSelectedClinicId] = useState<string | null>(null);

  const [selectedStatus, setSelectedStatus] =
    useState<IAppointmentStatus>('new');

  const [shouldRun, setShouldRun] = useState(true);

  const [selectedDateRange, setSelectedDateRange] = useState<{
    fromDate: string;
    toDate: string;
  }>(
    () =>
      getDateRangeByCalendarType(CALENDAR_TYPE.THIS_MONTH) as {
        fromDate: string;
        toDate: string;
      }
  );

  const [sort, setSort] = useState<'asc' | 'desc'>(defaultTableSortState.sort);

  const [sortBy, setSortBy] = useState(defaultTableSortState.sortBy);

  const [selectedCalendarType, setSelectedCalendarType] = useState(
    CALENDAR_TYPE.THIS_MONTH
  );

  const onChangeDateRange = (dateRange: {
    fromDate: string;
    toDate: string;
  }) => {
    setSelectedDateRange(dateRange);
  };

  const onChangeClinic = async (clinicId: string | null) => {
    setShouldRun(false);
    if (selectedCalendarType === CALENDAR_TYPE.ALL_TIME) {
      try {
        const rs = await axiosInstance.get('/appointments/date-range', {
          params: { clinicIds: clinicId ? [clinicId] : undefined },
        });
        onChangeDateRange({
          fromDate: moment(
            rs.data.earliestDateTime ?? selectedDateRange.fromDate
          ).format('YYYY-MM-DD'),
          toDate: moment(
            rs.data.latestDateTime ?? selectedDateRange.toDate
          ).format('YYYY-MM-DD'),
        });
      } catch (error) {}
    }
    setSelectedClinicId(clinicId);
    setShouldRun(true);
  };

  return (
    <div className="pt-2.4 pl-1.8 pr-4 text-14 leading-[2.1rem] text-darkest-grey pb-12 relative">
      <div className="flex flex-row justify-between">
        <h3 className="text-24 font-bold leading-[3.6rem]">Appointments</h3>
      </div>

      <div className="my-2.4 flex gap-x-1.6 items-center">
        <AppointmentStatus
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
        <ClinicFilter clinicId={selectedClinicId} onSelect={onChangeClinic} />

        <div className="flex-1 text-right">
          <AppointmentDateRange
            selectedCalendarType={selectedCalendarType}
            setSelectedCalendarType={setSelectedCalendarType}
            clinicId={selectedClinicId}
            dateRange={selectedDateRange}
            onChangeDateRange={onChangeDateRange}
          />
        </div>
      </div>

      <div className="bg-white shadow-primary rounded-[1rem]">
        <Table
          key={`${selectedStatus}${selectedDateRange.fromDate}${selectedDateRange.toDate}${selectedClinicId}`}
          clinicId={selectedClinicId}
          status={selectedStatus}
          dateRange={selectedDateRange}
          sort={sort}
          setSort={setSort}
          sortBy={sortBy}
          setSortBy={setSortBy}
          shouldRun={shouldRun}
        />
      </div>
    </div>
  );
};

export default AdminAppointmentPage;
