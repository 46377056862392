import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';
import { loadSelectedClinicId } from 'utils/storage';

interface IUseSplitSchedulingServiceParams {
  serviceId: string;
  shouldRun?: boolean;
}

const useSplitSchedulingService = ({
  serviceId,
  shouldRun,
}: IUseSplitSchedulingServiceParams) => {
  const clinicId = loadSelectedClinicId();
  const url = `clinics/${clinicId}/services/split-scheduling/${serviceId}`;

  const { data, error, mutate } = useSWR(
    shouldRun ? [url] : false,
    async (...resource) => {
      const [url] = resource;
      const response = await axiosInstance.get(url);
      return response.data;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useSplitSchedulingService;
