import React, { FC } from 'react';
import CommonButton from 'components/CommonButton/CommonButton';
import { ReactComponent as EmptyBucket } from 'assets/icons/empty-bucket.svg';

interface EmptyContentProps {
  labelFor: 'practice' | 'practitioner';
  onClickAdd?: () => void;
}

const EmptyContent: FC<EmptyContentProps> = ({ labelFor, onClickAdd }) => {
  return (
    <div className="pl-3.9 pt-2.4">
      <h3 className="leading-[3.6rem] text-24 font-bold">
        {labelFor === 'practice' ? 'Practices' : 'Practitioners'}
      </h3>
      <div className="flex flex-col justify-center items-center mt-14">
        <EmptyBucket />
        <span className="mt-2.4 text-16 leading-[2.4rem]">
          No {labelFor}s at the moment
        </span>
        {labelFor === 'practice' && (
          <CommonButton
            variant="secondary"
            className="mt-1.6"
            onClick={onClickAdd}
          >
            Add {labelFor}
          </CommonButton>
        )}
      </div>
    </div>
  );
};

export default EmptyContent;
