import { FC } from 'react';
import moment from 'moment';
import { ONE_MINUTE_IN_PX, getDurationRangeArray } from '../../utils';

interface CrossCellPerUnitDurationProps {
  unitDuration?: number;
  clinicWorkingHour: {
    startTime: string;
    endTime: string;
  };
  hour: number;
}

const CrossCellPerUnitDuration: FC<CrossCellPerUnitDurationProps> = ({
  unitDuration,
  clinicWorkingHour,
  hour,
}) => {
  if (!unitDuration) return null;

  const durationRangeArray = getDurationRangeArray(unitDuration);

  const momentStartWorkingHour = moment(clinicWorkingHour.startTime, 'HH:mm');
  const momentEndWorkingHour = moment(clinicWorkingHour.endTime, 'HH:mm');

  const isStartWorkingHour = moment(hour, 'HH').isSame(
    momentStartWorkingHour,
    'hour'
  );

  const isEndWorkingHour = moment(hour, 'HH').isSame(
    momentEndWorkingHour,
    'hour'
  );

  return (
    <div className="absolute w-full h-full flex flex-col justify-evenly isolate">
      {durationRangeArray.map((value) => (
        <div
          key={value}
          className="border-b-[0.5px] border-light-grey border-solid"
        />
      ))}
      {isStartWorkingHour && (
        <div
          className="w-full absolute top-0 bg-[#FBFBFB] bg-cross-container"
          style={{
            height: ONE_MINUTE_IN_PX * momentStartWorkingHour.get('minute'),
          }}
        />
      )}
      {isEndWorkingHour && (
        <div
          className="w-full bg-[#FBFBFB] absolute h-full bg-cross-container"
          style={{
            top: ONE_MINUTE_IN_PX * momentEndWorkingHour.get('minute'),
          }}
        />
      )}
    </div>
  );
};

export default CrossCellPerUnitDuration;
