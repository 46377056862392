import React, { FC, useEffect, useRef, useState } from 'react';
import { Listbox } from '@headlessui/react';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import CommonButton from 'components/CommonButton/CommonButton';
import DefaultModal from 'components/Modals/DefaultModal';
import useAppointmentDetail from 'hooks/useAppointmentDetail';
import useClinicPractitioner from 'hooks/useClinicPractitioner';
import { IPractitioner } from 'interfaces/practitioners';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { convertMinutesIntoHoursOfDay, datetimeFormat } from 'utils/datetime';
import getPractitionerName from 'utils/getPractitionerName';
import BookNowPanel from 'pages/DashboardPage/Table/BookNowPanel/BookNowPanel';
import axiosInstance from 'apis/axiosInstance';
import useMatchMutate from 'hooks/useMatchMutate';
import axios from 'axios';
import {
  ErrorAppointmentStatusType,
  ERROR_APPOINTMENT_STATUS,
  ERROR_REASON,
  ERROR_RESPONSE_SERVER,
} from 'utils/constants';
import { renderToast } from 'components/Toast/Toast';
import { getAppointmentStartTime } from 'components/TableColumn/utils/getAppointmentTime';
import { IAvailableBlocks } from 'hooks/usePractitionerWeeklyTimeSlot';
import {
  getDentistIdForSplitScheduling,
  getOperatoryId,
  isNoPreferenceOption,
  NO_PREFERENCE_OPTION,
} from './utils';
import { convertDurationForFE } from 'utils/convertDuration';
import { MINUTE_OF_A_BLOCK } from 'utils/getTimeBlockFromTImeString';
import useModal from 'hooks/useModal';
import useClinic from 'hooks/useClinic';

const {
  DUPLICATE_BOOKING,
  OUT_OF_OFFICE_HOURS,
  OUT_OF_PRACTITIONER_HOURS,
  UNFOUNDED_AVAILABLE_PRACTITIONER,
} = ERROR_REASON;

interface DuplicateAppointmentProps {
  id: string;
  onClose: () => void;
  onAppointmentAlreadyResolved: () => void;
  mode: string;
}
interface ISelectedTimeSlot {
  date: string;
  value: number;
  practitionerId?: string;
}

export type SelectedTimeSlot = ISelectedTimeSlot | null;

const SORT_BY = 'specialist,firstName';

const DuplicateAppointment: FC<DuplicateAppointmentProps> = ({
  id,
  onClose,
  onAppointmentAlreadyResolved,
  mode,
}) => {
  const {
    data: appointmentDetail,
    isLoading: isAppointmentDetailLoading,
    mutate: appointmentDetailMutate,
  } = useAppointmentDetail(id);

  const { data: practitionerList, isLoading: isPractitionerListLoading } =
    useClinicPractitioner({
      shouldRun: appointmentDetail,
      serviceId: appointmentDetail?.service.id,
      sortBy: SORT_BY,
    });

  const { data: clinic, isLoading: isClinicLoading } = useClinic();

  const [selectedPractitioner, setSelectedPractitioner] = useState<
    IPractitioner | null | typeof NO_PREFERENCE_OPTION
  >(null);

  const [selectedTimeSlot, setSelectedTimeSlot] =
    useState<SelectedTimeSlot>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const updatedAvailableBlocksRef = useRef<any[]>([]);

  const matchMutate = useMatchMutate();

  const isDataLoading =
    isAppointmentDetailLoading ||
    isPractitionerListLoading ||
    isClinicLoading ||
    !selectedPractitioner;

  const { setIsAllowed } = useModal({ onClose, isLoading: isDataLoading });

  useEffect(() => {
    if (appointmentDetail) {
      setSelectedPractitioner(
        appointmentDetail.isPreferredDoctor
          ? appointmentDetail.doctor
          : NO_PREFERENCE_OPTION
      );
    }
  }, [appointmentDetail]);

  const practitioners =
    practitionerList?.data.filter((item) => item.isActive) ?? [];

  const updatedPractitioners = [NO_PREFERENCE_OPTION, ...practitioners];

  const getConflictedPatientsName = () => {
    if (!appointmentDetail) return;
    return appointmentDetail.conflictedPatients.length > 0
      ? appointmentDetail.conflictedPatients[0].name
      : 'Event';
  };

  const getHeaderContent = () => {
    if (mode === DUPLICATE_BOOKING) {
      return 'Duplicate Appointment';
    }

    if (mode === OUT_OF_OFFICE_HOURS) {
      return 'Appointment scheduled outside office hours';
    }

    if (mode === OUT_OF_PRACTITIONER_HOURS) {
      return 'Appointment scheduled outside practitioner’s hours ';
    }

    if (mode === UNFOUNDED_AVAILABLE_PRACTITIONER) {
      return 'Modify Appointment';
    }

    if (!mode) {
      return 'Modify Appointment';
    }
  };

  const getBodyContent = () => {
    if (mode === DUPLICATE_BOOKING) {
      return (
        <>
          Oops… It looks like an appointment already exists on{' '}
          <strong>{renderAppointmentTime()}</strong> for{' '}
          <strong>{getConflictedPatientsName()}</strong>. Please select another
          time slot for <strong>{appointmentDetail.patient.name}</strong> or
          reschedule {getConflictedPatientsName()} in your practice software.
        </>
      );
    }
    if (mode === OUT_OF_OFFICE_HOURS) {
      return (
        <>
          This future appointment is scheduled for{' '}
          <strong>{renderAppointmentTime()}</strong>, which is outside of your
          new office hours. Please schedule another appointment time for{' '}
          <strong>{appointmentDetail.patient.name}</strong>.
        </>
      );
    }
    if (mode === OUT_OF_PRACTITIONER_HOURS) {
      const { firstName, lastName, title } = appointmentDetail.doctor;
      const fullName = `${firstName} ${lastName}`;

      return (
        <>
          This future appointment is scheduled for {renderAppointmentTime()},
          which is outside of {title} {fullName}’s new hours. Please schedule
          another appointment time for{' '}
          <strong>{appointmentDetail.patient.name}</strong>.
        </>
      );
    }

    if (mode === UNFOUNDED_AVAILABLE_PRACTITIONER) {
      return (
        <>
          Modify appointment for{' '}
          <strong>{appointmentDetail.patient.name}</strong>.
        </>
      );
    }

    if (!mode) {
      return (
        <>
          Modify appointment for{' '}
          <strong>{appointmentDetail.patient.name}</strong>.
        </>
      );
    }
  };

  const handleOnSubmit = async () => {
    const {
      SUCCESS,
      ERROR,
      ALREADY_RESOLVED,
      TIMESLOT_NOT_AVAILABLE,
      NULL_OPERATORY,
    } = ERROR_APPOINTMENT_STATUS;
    let status: ErrorAppointmentStatusType = SUCCESS;
    let operatoryId: string | null = null;
    try {
      setIsAllowed(false);
      setIsSubmitting(true);
      if (!selectedTimeSlot) {
        return null;
      }
      const practitioner = updatedAvailableBlocksRef.current.find(
        (doctor) => doctor.id === selectedTimeSlot.practitionerId
      );
      const availableBlocks = selectedTimeSlot.practitionerId
        ? practitioner.availableBlocks
        : updatedAvailableBlocksRef.current;
      operatoryId = getOperatoryId({ availableBlocks, selectedTimeSlot });
      // Check updated booking info for non-split to split service

      const dentist = getDentistIdForSplitScheduling({
        date: selectedTimeSlot!.date,
        timeSlot: selectedTimeSlot!.value,
        availableBlocks,
      });

      const updatedErrorApptPayload = {
        appointmentId: appointmentDetail.id,
        patientId: appointmentDetail.patient.id,
        doctorId: selectedTimeSlot.practitionerId
          ? selectedTimeSlot.practitionerId
          : selectedPractitioner!.id,
        appointmentDate: selectedTimeSlot!.date,
        startTime: convertMinutesIntoHoursOfDay({
          minutes: selectedTimeSlot!.value * MINUTE_OF_A_BLOCK,
          format: 'HH:mm:ss',
        }),
        operatoryId,
        dentist,
        isPreferredDoctor: selectedPractitioner !== NO_PREFERENCE_OPTION,
      };
      await axiosInstance.patch('/bookings', {
        ...updatedErrorApptPayload,
      });
      renderToast({
        message: 'Appointment updated successfully.',
        type: 'success',
      });
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data?.message ===
          ERROR_RESPONSE_SERVER.ALREADY_RESOLVED_MESSAGE
      ) {
        status = ALREADY_RESOLVED;
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data?.message ===
          ERROR_RESPONSE_SERVER.TIMESLOT_NOT_AVAILABLE_MESSAGE
      ) {
        status = TIMESLOT_NOT_AVAILABLE;
        renderToast({
          message:
            'Selected time slot is not available. Please select another time.',
          type: 'info',
        });
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data?.message ===
          ERROR_RESPONSE_SERVER.DUPLICATE_BOOKING
      ) {
        status = ERROR;
        renderToast({
          message:
            'It looks like the slot has already been taken. Waiting for appointments to be synced. Please reload the page after 15 minutes.',
          type: 'error',
        });
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data?.message ===
          ERROR_RESPONSE_SERVER.APPOINTMENT_NOT_SYNC_WITH_PMS
      ) {
        status = ERROR;
        renderToast({
          message:
            'It looks like the slot has not been written into PMS. Please wait 5-10 minutes for the synchronization to complete, then reload the page.',
          type: 'error',
        });
      } else if (!operatoryId) {
        status = NULL_OPERATORY;
        renderToast({
          message: 'An error has occurred. Please try again.',
          type: 'error',
        });
      } else {
        status = ERROR;
        renderToast({
          message: 'An error has occurred. Please try again.',
          type: 'error',
        });
      }
    }
    setIsAllowed(true);
    setIsSubmitting(false);
    if (status === NULL_OPERATORY || status === TIMESLOT_NOT_AVAILABLE) {
      setSelectedTimeSlot(null);
      return await matchMutate(/\/weekly-timeslots\?[\s\S]+/, {
        revalidate: true,
      });
    }
    if (status === ERROR) return;
    if (status === ALREADY_RESOLVED) {
      onAppointmentAlreadyResolved();
    }
    onClose();

    await Promise.all([
      // CLEAR CACHE FOR APPOINTMENT DETAIL MODAL
      appointmentDetailMutate(null, {
        revalidate: true,
      }),
      // UPDATE DATA FOR TABLE
      matchMutate(/\/appointments\?[\s\S]+/),
      // CLEAR CACHE DATA FOR WEEKLY TIMESLOT
      matchMutate(/[\s\S]+\/weekly-timeslots\?[\s\S]+/, {
        revalidate: true,
      }),
    ]);
  };

  const handleOnClose = () => {
    if (isSubmitting) return;
    onClose();
  };

  const getSelectedOptionLabel = () => {
    if (!selectedPractitioner) {
      return '';
    }
    if (isNoPreferenceOption(selectedPractitioner)) {
      return selectedPractitioner.label;
    }
    return getPractitionerName(selectedPractitioner);
  };

  const renderAppointmentTime = () => {
    return (
      <strong>
        {datetimeFormat({
          dateString: appointmentDetail.appointmentDate,
          format: 'YYYY-MM-DD',
          pattern: 'MMM DD, YYYY',
        })}{' '}
        at {getAppointmentStartTime(appointmentDetail)}
      </strong>
    );
  };

  const renderServiceInfo = () => {
    return (
      <p>
        {appointmentDetail.service.name} (
        {convertDurationForFE(appointmentDetail.service.duration)} min)
      </p>
    );
  };

  return (
    <DefaultModal>
      <div className="w-[70.2rem] h-[74.5rem] p-3 text-darkest-grey text-14 normal-case">
        <h3 className="font-bold text-20 leading-[3rem]">
          {getHeaderContent()}
        </h3>
        {isDataLoading ? (
          <div className="w-full h-full flex justify-center mt-10">
            <LoadingSpinner className="all-child:fill-magenta" />
          </div>
        ) : (
          <>
            <p className="leading-[2.1rem] mt-1.6">{getBodyContent()}</p>
            <div className="h-[47.4rem] shadow-primary rounded-2xl border border-lightest-grey mt-2.2">
              <div className="px-3 pt-2.5 grid grid-rows-2 gap-y-0.9">
                <p className="font-bold text-16 leading-[2.4rem] basis-[26rem]">
                  Select another schedule for {appointmentDetail.patient.name}
                </p>
                <div className="flex justify-between">
                  {renderServiceInfo()}

                  <div className="relative">
                    <Listbox
                      value={selectedPractitioner}
                      onChange={(option) => {
                        setSelectedTimeSlot(null);
                        setSelectedPractitioner(option);
                      }}
                    >
                      <Listbox.Button className="flex items-center gap-x-0.8 relative">
                        <span className="font-bold w-[15rem] text-right truncate">
                          {getSelectedOptionLabel()}
                        </span>
                        <ArrowDownIcon />
                      </Listbox.Button>
                      <Listbox.Options className="absolute mt-0.5 w-[20rem] overflow-auto rounded-[2rem] bg-white py-1 text-base p-2.4 flex flex-col gap-y-1.6 rouded-[2rem] border border-[#E8E8E8] z-10 max-h-15 scrollbar">
                        {updatedPractitioners.map((practitioner) => (
                          <Listbox.Option
                            key={practitioner.id}
                            value={practitioner}
                            className={({ active, selected }) =>
                              `cursor-pointer ${
                                (active ||
                                  selected ||
                                  practitioner.id ===
                                    selectedPractitioner.id) &&
                                'text-magenta'
                              }`
                            }
                          >
                            {isNoPreferenceOption(practitioner)
                              ? practitioner.label
                              : getPractitionerName(practitioner)}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Listbox>
                  </div>
                </div>
                <BookNowPanel
                  practitionerId={
                    isNoPreferenceOption(selectedPractitioner)
                      ? null
                      : selectedPractitioner.id
                  }
                  appointmentId={id}
                  selectedTimeSlot={selectedTimeSlot}
                  updateAvailableBlocksRef={(data: IAvailableBlocks[]) => {
                    updatedAvailableBlocksRef.current = data;
                  }}
                  setSelectedTimeSlot={setSelectedTimeSlot}
                  isOutsideHourMode={mode !== DUPLICATE_BOOKING}
                  slotInterval={clinic.slotInterval}
                />
              </div>
            </div>
            <div className="flex justify-end gap-x-1.6 mt-4.1">
              <CommonButton variant="secondary" onClick={handleOnClose}>
                Cancel
              </CommonButton>
              <CommonButton
                className="w-[18rem]"
                onClick={handleOnSubmit}
                isLoading={isSubmitting}
                disabled={!selectedTimeSlot?.value}
              >
                Update Appointment
              </CommonButton>
            </div>
          </>
        )}
      </div>
    </DefaultModal>
  );
};

export default DuplicateAppointment;
