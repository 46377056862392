import { FC, useState } from 'react';
import { Switch } from '@headlessui/react';
import { Controller } from 'react-hook-form';
import { ReactComponent as TriangleIcon } from 'assets/icons/triangle.svg';

import AssignedDoctorSection from './AssignedDoctorSection/AssignedDoctorSection';
import ExamPlacementSection from './ExamPlacementSection/ExamPlacementSection';
import DurationSection from './DurationSection/DurationSection';
import { Specialty } from 'interfaces/specialty';

interface AdvancedSectionProps {
  control: any;
  isSplitScheduling?: boolean;
  timeInterval?: number;
  hygienistDuration?: number | null;
  doctorDuration?: number | null;
  doctors: {
    id: string;
    avatar: string;
    firstName: string;
    name: string;
    title: string;
    specialist: string;
    specialties: Specialty[];
  }[];
}

const AdvancedSection: FC<AdvancedSectionProps> = ({
  control,
  isSplitScheduling,
  doctorDuration,
  hygienistDuration,
  timeInterval = 0,
  doctors,
}) => {
  const [isAdvancedSectionOpened, setIsAdvancedSectionOpened] = useState(true);

  return (
    <div>
      <button
        className="flex gap-x-0.8"
        onClick={() => setIsAdvancedSectionOpened(!isAdvancedSectionOpened)}
      >
        <TriangleIcon
          className={`${
            isAdvancedSectionOpened ? '' : '-rotate-90'
          } transition-transform`}
        />
        <span className="font-bold text-16 leading-[2.4rem]">Advanced</span>
      </button>
      {isAdvancedSectionOpened && (
        <>
          <div className="flex gap-x-10 items-center mt-2">
            <label className="font-bold text-16 leading-[2.4rem]" htmlFor="">
              Split Scheduling
            </label>
            <div className="flex gap-x-1.6 items-center">
              <Controller
                control={control}
                name="isSplitScheduling"
                render={({ field }) => (
                  <>
                    <Switch
                      {...field}
                      checked={field.value}
                      className={`${
                        field.value ? 'bg-magenta' : 'bg-[#78788029]'
                      } relative inline-flex h-3.1 w-5.1 items-center rounded-full`}
                    >
                      <span
                        className={`${
                          field.value
                            ? 'translate-x-[2.3rem]'
                            : 'translate-x-[2px]'
                        } inline-block h-2.5 w-2.5 transform rounded-full transition bg-white`}
                      />
                    </Switch>
                    <span className="uppercase">
                      {field.value ? 'On' : 'Off'}
                    </span>
                  </>
                )}
              />
            </div>
          </div>
          {isSplitScheduling && (
            <div className="flex flex-col gap-y-2.4 mt-2.4">
              <AssignedDoctorSection control={control} doctors={doctors} />
              <ExamPlacementSection
                control={control}
                isSplitScheduling={isSplitScheduling}
              />
              <DurationSection
                control={control}
                isSplitScheduling={isSplitScheduling}
                hygienistDuration={hygienistDuration}
                doctorDuration={doctorDuration}
                timeInterval={timeInterval}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AdvancedSection;
