import useSWR from 'swr';

import axiosInstance from 'apis/axiosInstance';
import { loadSelectedClinicId } from 'utils/storage';
import { sortBy } from 'lodash';

interface IUseClinicNextAvailabilityParams {
  shouldRun?: boolean;
  serviceId: string;
  date: string;
}

const useClinicNextAvailability = ({
  shouldRun,
  serviceId,
  date,
}: IUseClinicNextAvailabilityParams) => {
  const clinicId = loadSelectedClinicId();

  const { data, error } = useSWR(
    // Request depend on startDateBlock as well
    shouldRun
      ? `/clinics/${clinicId}/appointments/next-availability?date=${date}`
      : null,
    async (key) => {
      const url = key.split('?')[0];
      const response = await axiosInstance.post<{
        doctors: { doctorId: string; nextAvailabilityDate: string }[];
      }>(url, {
        serviceId,
        date: date,
      });

      const sortedResultByDate = sortBy(
        response.data?.doctors,
        (item) => item.nextAvailabilityDate
      );

      const firstItem = sortedResultByDate?.[0];

      return firstItem?.nextAvailabilityDate
        ? { nextAvailabilityDate: firstItem.nextAvailabilityDate }
        : { nextAvailabilityDate: null };
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
  };
};

export default useClinicNextAvailability;
