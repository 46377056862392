import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu } from '@headlessui/react';

import { ADMIN_ROUTES, ROUTES } from 'utils/constants/routes';
import { ReactComponent as Logo } from 'assets/images/app-logo.svg';
import { ClinicUserRole, useUser } from 'context/userContext';
import {
  clearIsPageDirty,
  clearStorage,
  getIsPageDirty,
  loadAuthToken,
  saveRole,
  saveSelectedClinicId,
} from 'utils/storage';
import { capitalizeAvatarName, isInAdminPage } from 'utils/common';
import axiosInstance from 'apis/axiosInstance';
import LeavePageModal from 'components/LeavePageModal/LeavePageModal';

const AuthNavigationBar = () => {
  const { user, dispatch } = useUser();
  const { currentClinicUserRole, globalAdminRole } = user;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedClinicUserRole, setSelectedClinicUserRole] =
    useState<ClinicUserRole | null>(null);

  const navigate = useNavigate();

  const location = useLocation();

  const refreshToken = loadAuthToken()?.refreshToken;

  const fullName = globalAdminRole
    ? `${globalAdminRole.firstName
        .replace(/\s/g, '')
        .trim()} ${globalAdminRole.lastName.replace(/\s/g, '').trim()}`
    : `${currentClinicUserRole?.firstName
        .replace(/\s/g, '')
        .trim()} ${currentClinicUserRole?.lastName.replace(/\s/g, '').trim()}`;

  const avatar = capitalizeAvatarName(fullName);

  const handleLogout = async () => {
    if (getIsPageDirty()) {
      return setIsModalOpen(true);
    }

    navigate(ROUTES.LOGIN);
    clearStorage();
    dispatch({ type: 'logout' });

    try {
      await axiosInstance.post('/clinic-user/auth/logout', null, {
        headers: {
          Authorization: `JWT ${refreshToken}`,
        },
      });
    } catch (error) {}
  };

  const handleChangeClinic = async (clinicUserRole: ClinicUserRole) => {
    if (getIsPageDirty()) {
      setSelectedClinicUserRole(clinicUserRole);
      return setIsModalOpen(true);
    }
    const clinicId = clinicUserRole.clinic.id;
    const role = clinicUserRole.role;
    saveSelectedClinicId(clinicId);
    saveRole(role);
    dispatch({ type: 'changeClinic', payload: { clinicId } });
  };

  const handleOnModalSubmit = async () => {
    clearIsPageDirty();

    if (selectedClinicUserRole) {
      await handleChangeClinic(selectedClinicUserRole);
      return;
    }
    await handleLogout();
  };

  return (
    <>
      {isModalOpen && (
        <LeavePageModal
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleOnModalSubmit}
        />
      )}
      <div className="h-6 flex items-center px-2.4 justify-between bg-white border-b border-b-light-grey sticky top-0 z-50">
        <Link
          to={
            isInAdminPage(location) ? ADMIN_ROUTES.DASHBOARD : ROUTES.DASHBOARD
          }
        >
          <div className="flex flex-row justify-between items-center">
            <Logo />
            {process.env.REACT_APP_STAGE_ENV !== 'production' && (
              <div className="bg-magenta white rounded-full text-white font-bold text-12 h-2.6 leading-[26px] mt-1 ml-1 px-1">
                STAGING
              </div>
            )}
          </div>
        </Link>
        <div className="flex gap-x-4 items-center">
          <Menu as={'div'} className="relative">
            <Menu.Button className="flex items-center text-14 cursor-pointer font-bold">
              <div className="w-3 h-3 bg-magenta white flex justify-center items-center rounded-full text-white font-bold">
                {avatar}
              </div>
            </Menu.Button>
            <Menu.Items className="absolute right-0 mt-0.8 w-21 bg-white rounded-[1rem] shadow-logout-shadow text-darkest-grey text-14 px-2">
              <Menu.Item
                as={'div'}
                className="flex items-center gap-x-1 font-bold py-1.6 border-b border-light-grey"
                disabled
              >
                <div className="w-3 h-3 bg-magenta white flex justify-center items-center rounded-full text-white">
                  {avatar}
                </div>
                <span>
                  {globalAdminRole
                    ? globalAdminRole.name
                    : currentClinicUserRole?.name}
                </span>
              </Menu.Item>
              <Menu.Item
                as="button"
                className="w-[calc(100%+4rem)] px-2 py-1.6 -mx-2 text-left hover:text-magenta"
                onClick={handleLogout}
              >
                Sign out
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
      </div>
    </>
  );
};

export default AuthNavigationBar;
