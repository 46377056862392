import { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { Controller } from 'react-hook-form';
import { Listbox } from '@headlessui/react';

import { EXAM_PLACEMENT } from 'utils/constants';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';

interface ExamPlacementSectionProps {
  control: any;
  isSplitScheduling: boolean;
}

const EXAM_PLACEMENT_OPTIONS = [
  { label: 'Before/after hygiene appointment', value: null },
  { label: 'Before hygiene appointment', value: EXAM_PLACEMENT.HEAD },
  { label: 'After hygiene appointment', value: EXAM_PLACEMENT.TAIL },
];

const ExamPlacementSection: FC<ExamPlacementSectionProps> = ({
  control,
  isSplitScheduling,
}) => {
  return (
    <div className="flex flex-col gap-y-0.8">
      <div className="flex items-center gap-x-1">
        <label className="font-bold text-16 leading-[2.4rem]" htmlFor="">
          Exam Placement
        </label>
        <button data-for="exam-placement-info" data-tip="">
          <InfoIcon className="w-1.6 h-1.6 all-child:fill-mid-grey" />
        </button>
        <ReactTooltip
          id="exam-placement-info"
          effect="solid"
          place="bottom"
          className="!bg-white !rounded-[0.8rem] !w-[18.5rem] !h-[auto] z-[9999] flex justify-center items-center !opacity-100 shadow-elevation-07 after:hidden before:hidden !px-[10px] !py-[4px]"
        >
          <span className="text-11 text-darkest-grey flex items-center font-normal">
            This setting will apply to all exam and cleaning services with
            activated split scheduling.
          </span>
        </ReactTooltip>
      </div>
      <Controller
        control={control}
        name={'examPlacement'}
        render={({ field }) => {
          return (
            <Listbox {...field} disabled={!isSplitScheduling}>
              {({ open }) => {
                return (
                  <div className="relative">
                    <Listbox.Button
                      className={`relative w-full h-[4.8rem] pl-1.6 cursor-pointer rounded-[1rem] bg-white border-[0.5px] border-light-grey text-left shadow-primary hover:border-magenta hover:shadow-input-active outline-none ${
                        open ? 'border-magenta shadow-input-active' : ''
                      }`}
                    >
                      <span className="block pr-3 text-darkest-grey">
                        {
                          EXAM_PLACEMENT_OPTIONS.find(
                            (item) => item.value === field.value
                          )!.label
                        }
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <DropdownIcon className="w-1.4 h-1.4 " />
                      </span>
                    </Listbox.Button>
                    <Listbox.Options className="absolute mt-0.8 w-full rounded-[0.8rem] bg-white py-1.5 text-base shadow-primary flex flex-col gap-y-1.5 z-20">
                      {EXAM_PLACEMENT_OPTIONS.map(({ label, value }) => (
                        <Listbox.Option
                          key={value}
                          className="relative cursor-default select-none pl-1.6"
                          value={value}
                        >
                          {({ selected }) => (
                            <span
                              className={`block truncate cursor-pointer hover:text-magenta ${
                                selected ? 'text-magenta' : ''
                              }`}
                            >
                              {label}
                            </span>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </div>
                );
              }}
            </Listbox>
          );
        }}
      />
    </div>
  );
};

export default ExamPlacementSection;
