import { Specialty } from 'interfaces/specialty';
import { SPECIALTY_NAME } from './constants';

const getPractitionerSpecialists = <
  T extends { specialties: Specialty[]; specialist: string }
>(
  practitioner: T
) => {
  if (practitioner.specialist === SPECIALTY_NAME.BOTH) {
    const excludeHygienist = practitioner.specialties.filter(
      (specialty) => specialty.name !== SPECIALTY_NAME.HYGIENIST
    );
    return excludeHygienist.map((item) => item.name).join(', ');
  }

  return practitioner.specialties.map((item) => item.name).join(', ');
};

export default getPractitionerSpecialists;
