import { FC, useEffect, useRef } from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as FirstInIcon } from 'assets/icons/firstin-icon.svg';
import { ReactComponent as PhoneIphoneIcon } from 'assets/icons/phone_iphone.svg';
import { ReactComponent as BirthdayIcon } from 'assets/icons/cake.svg';
import { ReactComponent as ServiceIcon } from 'assets/icons/service.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/time-line.svg';
import { ReactComponent as PractitionerIcon } from 'assets/icons/practitioners-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as MailOutlineIcon } from 'assets/icons/mail_outline.svg';
import { ReactComponent as EventSeatIcon } from 'assets/icons/event_seat.svg';
import { ReactComponent as SplitScheduleIcon } from 'assets/icons/call_split.svg';
import { ERROR_REASON_MESSAGE, PMS_TYPE } from 'utils/constants';
import { DATE_TIME_FORMAT, datetimeFormat } from 'utils/datetime';
import useAppointmentDetail from 'hooks/useAppointmentDetail';
import AppointmentDetailStatus from 'components/AppointmentDetailModal/AppointmentDetailStatus/AppointmentDetailStatus';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import {
  calculateAge,
  capitalizeAvatarName,
  getErrorReason,
} from 'utils/common';
import { formatFullPhoneNumber } from 'utils/formatPhoneNumber';
import ErrorReason from 'components/ErrorReason/ErrorReason';
import { getDentistWorkingHour } from 'utils/getDentistWorkingHour';
import {
  getDoctorInfo,
  getHygienistInfo,
  getIsDoctorStartBeforeHyginiest,
} from './utils/getPractitionerDetail';
import getPractitionerName from 'utils/getPractitionerName';
import { MINUTE_OF_A_BLOCK } from 'utils/getTimeBlockFromTImeString';

interface CardDetailProps {
  appointmentId: string;
  onClose: () => void;
  className?: string;
}

const CardDetail: FC<CardDetailProps> = ({
  appointmentId,
  onClose,
  className,
}) => {
  const { data, isLoading } = useAppointmentDetail(appointmentId);

  const containerRef = useRef<HTMLDivElement>(null);

  const errorReason = data ? getErrorReason(data) : null;

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    container.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  });

  useEffect(() => {
    const handlePressEscapeButton = (e: KeyboardEvent) => {
      if (e.key !== 'Escape') return;
      onClose();
    };

    window.addEventListener('keyup', handlePressEscapeButton);

    return () => {
      window.removeEventListener('keyup', handlePressEscapeButton);
    };
  }, [onClose]);

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      const containerElement = containerRef.current;
      if (!containerElement) return;
      const isUserClickInsideContainer = containerElement.contains(
        e.target as Node
      );
      if (!isUserClickInsideContainer) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [onClose]);

  const renderDentistHygienistInfo = () => {
    if (data.linkedAppointment) {
      return (
        <>
          <button data-for={data.id} data-tip="">
            <SplitScheduleIcon className="all-child:all-child:fill-[#4D97E1]" />
          </button>
          <ReactTooltip
            id={data.id}
            place="bottom"
            effect="solid"
            className="!bg-white !rounded-[0.8rem] !min-w-[22.4rem] !h-[auto] z-[9999] flex !opacity-100 shadow-elevation-07 after:!hidden !px-1.6 !py-0.7 !border-none"
            offset={{ right: 70 }}
          >
            <div className="text-11 text-darkest-grey flex flex-col font-normal leading-[1.8rem]">
              <h3 className="font-bold">Split Appointment</h3>
              <div
                className={`flex flex-col ${
                  getIsDoctorStartBeforeHyginiest(data)
                    ? ''
                    : 'flex-col-reverse'
                }`}
              >
                <span>Doctor: {getDoctorInfo(data)}</span>
                <span>Hygienist: {getHygienistInfo(data)} </span>
              </div>
            </div>
          </ReactTooltip>
        </>
      );
    }

    if (data.isSplitScheduling) {
      return (
        <>
          <button data-for={data.id} data-tip="">
            <SplitScheduleIcon className="all-child:all-child:fill-[#4D97E1]" />
          </button>
          <ReactTooltip
            id={data.id}
            place="bottom"
            effect="solid"
            className="!bg-white !rounded-[0.8rem] !min-w-[22.4rem] !h-[auto] z-[9999] flex !opacity-100 shadow-elevation-07 after:!hidden !px-1.6 !py-0.7 !border-none"
            offset={{ right: 70 }}
          >
            <div className="text-11 text-darkest-grey flex flex-col font-normal leading-[1.8rem]">
              <h3 className="font-bold">Split Appointment</h3>
              <div className="flex flex-col-reverse">
                <span>Doctor: {getDentistWorkingHour(data)}</span>
                <span>Hygienist: {getHygienistInfo(data)} </span>
              </div>
            </div>
          </ReactTooltip>
        </>
      );
    }

    return null;
  };

  return (
    <div
      className={`absolute z-[100] select-none rounded-[1.6rem] p-3 shadow-elevation-08 bg-white -translate-y-[70%] w-[42rem] h-[56rem] overflow-auto scrollbar ${className}`}
      ref={containerRef}
    >
      <button onClick={onClose} className="top-3 right-3 absolute">
        <CloseIcon />
      </button>
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <LoadingSpinner className="all-child:fill-magenta" />
        </div>
      ) : (
        <>
          <section className="flex gap-x-1.2">
            {data.pmsType === PMS_TYPE.FIRST_IN && (
              <FirstInIcon className="w-2.4 h-2.4" />
            )}
            {renderDentistHygienistInfo()}
            <AppointmentDetailStatus appointment={data} />
          </section>

          <section className="flex mt-1.6 gap-x-2.4 items-center">
            <div className="w-6 h-6 bg-magenta rounded-full flex items-center justify-center text-white text-16 shrink-0">
              {data.patient &&
                capitalizeAvatarName(
                  `${data.patient.firstName?.replace(/\s/g, '').trim() || ''} ${
                    data.patient.lastName?.replace(/\s/g, '').trim() || ''
                  }`
                )}
            </div>
            <div className="flex flex-1 flex-col pt-1.2">
              <span className="text-18 leading-[2.7rem] font-bold">
                {data.patient
                  ? `${data.patient.name}${
                      calculateAge(data.patient.dob, 'MMM DD, YYYY')
                        ? `, ${calculateAge(data.patient.dob, 'MMM DD, YYYY')}`
                        : ''
                    }`
                  : '-'}
              </span>
              <span className="text-12 leading-[1.8rem]">
                Requested on:{' '}
                {moment(data.createdAt).format('MMM DD, YYYY hh:mm A')}
              </span>
            </div>
          </section>

          <main className="mt-3 flex flex-col gap-y-2.4">
            <section className="space-y-1">
              <h3 className="uppercase text-11 leading-[2.1rem] font-bold text-dim-grey mb-1.2">
                Patient Info
              </h3>
              <div className="flex gap-x-1 items-center">
                <MailOutlineIcon className="w-2 h-2 all-child:all-child:fill-mid-grey" />
                <span>{data.patient?.email || '-'}</span>
              </div>

              <div className="flex gap-x-1 items-center">
                <PhoneIphoneIcon className="w-2 h-2 all-child:all-child:fill-mid-grey" />
                <span>
                  {data.patient?.phoneNumber
                    ? formatFullPhoneNumber(`+${data.patient.phoneNumber}`)
                    : '-'}
                </span>
              </div>

              <div className="flex gap-x-1 items-center">
                <BirthdayIcon className="w-2 h-2 all-child:all-child:fill-mid-grey" />
                <span>
                  {data.patient?.dob
                    ? datetimeFormat({
                        dateString: data.patient.dob,
                        pattern: DATE_TIME_FORMAT.DOB,
                      })
                    : '-'}
                </span>
              </div>
            </section>
            <section className="space-y-1">
              <h3 className="uppercase text-11 leading-[2.1rem] font-bold text-dim-grey mb-1.2">
                Booking Details
              </h3>
              <div className="flex gap-x-1 items-center mt-0.2">
                <ServiceIcon className="w-2 h-2 all-child:fill-mid-grey" />
                <span>{data.service?.name || '-'}</span>
              </div>
              <div className="flex gap-x-1 items-center">
                <TimeIcon className="w-2 h-2 all-child:fill-mid-grey" />
                <span>
                  {datetimeFormat({
                    dateString: data.appointmentDate,
                    pattern: 'MMM DD, YYYY',
                  })}{' '}
                  {data.startTime} {' - '}
                  {moment(data.startTime, 'hh:mm A')
                    .add(data.duration * MINUTE_OF_A_BLOCK, 'minute')
                    .format('hh:mm A')}
                </span>
              </div>
              <div className="flex gap-x-1 items-center">
                <PractitionerIcon className="w-2 h-2 all-child:all-child:fill-mid-grey" />
                <span>{getPractitionerName(data.doctor)}</span>
              </div>
              <div className="flex gap-x-1 items-center">
                <EventSeatIcon className="w-2 h-2 all-child:all-child:fill-mid-grey" />
                <span>{data.operatory.name}</span>
              </div>
              {errorReason && (
                <div className="flex gap-x-1 items-center">
                  <ErrorReason
                    errorReason={errorReason}
                    className="!w-2 !h-2 all-child:fill-secondary-red all-child:all-child:fill-secondary-red"
                  />
                  <span>{ERROR_REASON_MESSAGE[errorReason]}</span>
                </div>
              )}
            </section>
            <section className="space-y-1">
              <h3 className="uppercase text-11 leading-[2.1rem] font-bold text-dim-grey mb-1.2">
                Last appointment
              </h3>
              <span>
                {data.lastPastAppointment
                  ? `${datetimeFormat({
                      dateString: data.lastPastAppointment.appointmentDate,
                      pattern: 'MMM DD, YYYY',
                    })} - ${getPractitionerName(
                      data.lastPastAppointment.doctor
                    )}`
                  : '-'}
              </span>
            </section>
            <section className="space-y-1">
              <h3 className="uppercase text-11 leading-[2.1rem] font-bold text-dim-grey mb-1.2">
                Next appointment
              </h3>
              <span>
                {data.nextFutureAppointment
                  ? `${datetimeFormat({
                      dateString: data.nextFutureAppointment.appointmentDate,
                      pattern: 'MMM DD, YYYY',
                    })} - ${getPractitionerName(
                      data.nextFutureAppointment.doctor
                    )}`
                  : '-'}
              </span>
            </section>
            <section className="space-y-1">
              <h3 className="uppercase text-11 leading-[2.1rem] font-bold text-dim-grey mb-1.2">
                Note
              </h3>
              <span>
                {data.pmsNote ? <p className="mt-1.2">{data.pmsNote}</p> : '-'}
              </span>
            </section>
          </main>
        </>
      )}
    </div>
  );
};

export default CardDetail;
