import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { capitalize } from 'lodash';
import axios from 'axios';
import { BAD_REQUEST } from 'utils/constants/statusCode';

import CommonButton from 'components/CommonButton/CommonButton';
import DefaultModal from 'components/Modals/DefaultModal';
import ProfileInfo from 'components/ProfileInfo/ProfileInfo';
import TextField from 'components/TextField/TextField';
import { IPractitioner } from 'interfaces/practitioners';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import NoAvatarPractitioner from 'assets/images/no-avatar.svg';
import ProviderIdDropdown from '../MainSection/PractitionerProfile/ProviderIdDropdown/ProviderIdDropdown';
import GenderDropdown from '../MainSection/PractitionerProfile/GenderDropdown/GenderDropdown';
import StudyDropdown from '../MainSection/PractitionerProfile/StudyDropdown/StudyDropdown';
import ServiceDropdown from '../MainSection/PractitionerProfile/ServiceDropdown/ServiceDropdown';
import OperatoryDropdown from '../MainSection/PractitionerProfile/OperatoryDropdown/OperatoryDropdown';

import TitleDropdown from '../MainSection/PractitionerProfile/TitleDropdown/TitleDropdown';
import { loadAuthToken, loadRole, loadSelectedClinicId } from 'utils/storage';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import { PMS_TYPE, ROLE } from 'utils/constants';
import useMatchMutate from 'hooks/useMatchMutate';
import SpecialtyDropdown from '../MainSection/PractitionerProfile/SpecialtyDropdown/SpecialtyDropdown';
import OptimizedScheduleToggle from '../MainSection/PractitionerProfile/OptimizedScheduleToggle/OptimizedScheduleToggle';
import useModal from 'hooks/useModal';
interface IProps {
  onClose: () => void;
  setSelectedPractitioner: React.Dispatch<
    React.SetStateAction<IPractitioner | null>
  >;
}

const CreatePractitionerModal = ({
  onClose,
  setSelectedPractitioner,
}: IProps) => {
  const matchMutate = useMatchMutate();
  const [isEnabled, setIsEnabled] = useState(false);

  const { setIsAllowed } = useModal({ onClose });

  const initialValue = useMemo(() => {
    return {
      isActive: true,
      externalId: '',
      firstName: '',
      lastName: '',
      gender: '',
      specialties: [],
      title: '',
      studies: [],
      services: [],
      operatories: [],
      bio: '',
      avatar: '',
      uploadedFile: undefined,
      optimizeSchedule: null,
    };
  }, []);

  const {
    control,
    getValues,
    setValue,
    watch,
    register,
    formState,
    handleSubmit,
  } = useForm<any>({
    mode: 'all',
    defaultValues: initialValue,
  });

  const handleUploadImage = async () => {
    const accessToken = loadAuthToken()?.accessToken;
    const uploadedFile = getValues('uploadedFile');
    if (!uploadedFile) return;

    const formData = new FormData();
    formData.append('file', uploadedFile);
    const response = await axiosInstance.post(
      `/upload/avatar/practitioners`,
      formData,
      {
        headers: {
          Authorization: `JWT ${accessToken}`,
          'Content-Type': 'multipart/form-data',
          ...(loadRole() === ROLE.PRACTICE_ADMIN
            ? {
                'X-ClinicId': loadSelectedClinicId(),
              }
            : {}),
        },
      }
    );
    return response.data.url;
  };

  const onSubmit = async () => {
    setIsAllowed(false);
    try {
      const clinicId = loadSelectedClinicId();
      const accessToken = loadAuthToken()?.accessToken;

      const avatar = await handleUploadImage();

      const formData = getValues();

      const normalizedPractitionerPayload = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        gender: formData.gender,
        title: formData.title,
        clinic: {
          clinicId,
          externalDoctorId: formData.externalId,
          pmsType: PMS_TYPE.NEX_HEALTH,
        },
        avatar,
        bio: formData.bio,
        isActive: true,
        services: formData.services.map((item: any) => ({
          clinicId,
          serviceId: item.value,
        })),
        studyIds: formData.studies.map((item: any) => item.value),
        operatoryIds: formData.operatories.map((item: any) => item.value),
        specialtyIds: formData.specialties.map((item: any) => item.value),
      };

      const response = await axiosInstance.post(
        '/practitioners',
        normalizedPractitionerPayload,
        {
          headers: {
            Authorization: `JWT ${accessToken}`,
            'X-ClinicId': clinicId,
          },
        }
      );

      const newlyCreatedPractitionerId = response.data.id;

      const [mutateResponse] = await Promise.all([
        matchMutate(/\/practitioners/),
      ]);

      const updatedPractitioners = mutateResponse[1].data;

      const newlyCreatedPractitioner = updatedPractitioners.find(
        (practitioner: IPractitioner) =>
          practitioner.id === newlyCreatedPractitionerId
      );

      setSelectedPractitioner(newlyCreatedPractitioner);
      setIsAllowed(true);
      onClose();
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response?.status === BAD_REQUEST) {
        return renderToast({
          message: error.response?.data.message,
          type: 'error',
        });
      }

      renderToast({
        message: 'Something went wrong. Please try again later',
        type: 'error',
      });
    }
  };

  const onChangeOptimizedSchedule = () => {
    const isUpdatedValueEnabled = !isEnabled;
    if (!isUpdatedValueEnabled) {
      setValue('optimizeSchedule', null);
    } else {
      setValue('optimizeSchedule', initialValue.optimizeSchedule);
    }
    setIsEnabled(isUpdatedValueEnabled);
  };

  return (
    <DefaultModal>
      <div className="w-[88.2rem] h-[auto] p-2.1 pb-0 text-darkest-grey text-14 normal-case">
        <h3 className="font-bold text-20 leading-[3rem]">
          Create new practitioner
        </h3>

        <div className="py-3 h-[60rem] scrollbar overflow-y-auto -mr-1.8 pr-1">
          <div className="flex justify-between">
            <div className="flex flex-col gap-y-1.6">
              <ProfileInfo
                title={'Practitioner ID'}
                data={getValues('externalId')}
                isEdit
              >
                <div className="min-w-[38rem]">
                  <ProviderIdDropdown
                    control={control}
                    rules={{
                      required: 'This field is required',
                    }}
                    disabled={false}
                    setValue={setValue}
                  />
                </div>
              </ProfileInfo>

              <ProfileInfo
                title={'Title'}
                data={getValues('title')}
                isEdit
                isOptional
              >
                <div className="min-w-[38rem]">
                  <TitleDropdown control={control} />
                </div>
              </ProfileInfo>

              <ProfileInfo
                title={'First Name'}
                data={getValues('firstName')}
                isEdit
              >
                <div className="min-w-[38rem]">
                  <TextField
                    placeholder="Input First Name"
                    id={'firstName'}
                    control={control}
                    defaultValue={getValues('firstName')}
                    rules={{
                      required: 'This field is required',
                    }}
                  />
                </div>
              </ProfileInfo>

              <ProfileInfo
                title={'Last Name'}
                data={getValues('lastName')}
                isEdit
              >
                <div className="min-w-[38rem]">
                  <TextField
                    placeholder="Input Last Name"
                    id={'lastName'}
                    control={control}
                    defaultValue={getValues('lastName')}
                    rules={{
                      required: 'This field is required',
                    }}
                  />
                </div>
              </ProfileInfo>

              <ProfileInfo
                title={'Gender'}
                data={capitalize(getValues('gender'))}
                isEdit
              >
                <div className="min-w-[38rem]">
                  <GenderDropdown
                    control={control}
                    rules={{
                      required: 'This field is required',
                    }}
                  />
                </div>
              </ProfileInfo>

              <ProfileInfo
                title={'Suffix'}
                data={(getValues('studies') || [])
                  .map((item: any) => item.label)
                  .join(', ')}
                isEdit
                isOptional
              >
                <div className="min-w-[38rem]">
                  <StudyDropdown control={control} />
                </div>
              </ProfileInfo>

              <ProfileInfo
                title={'Specialty'}
                data={getValues('specialty')}
                isEdit
              >
                <div className="min-w-[38rem]">
                  <SpecialtyDropdown
                    control={control}
                    rules={{
                      required: 'This field is required',
                    }}
                    specialties={watch('specialties')}
                    setValue={setValue}
                    services={watch('services')}
                  />
                </div>
              </ProfileInfo>

              <ProfileInfo
                title="Services"
                data={(getValues('services') || [])
                  .map((item: any) => item.label)
                  .join(', ')}
                isEdit
                isOptional
              >
                <div className="min-w-[38rem]">
                  <ServiceDropdown
                    control={control}
                    specialties={watch('specialties')}
                  />
                </div>
              </ProfileInfo>

              <ProfileInfo
                title="Chairs"
                data={(getValues('operatories') || [])
                  .map((item: any) => item.label)
                  .join(', ')}
                isEdit
                isOptional
              >
                <div className="min-w-[38rem]">
                  <OperatoryDropdown control={control} />
                </div>
              </ProfileInfo>

              <ProfileInfo
                title="Optimize Schedule"
                data={
                  getValues('optimizeSchedule')
                    ? `±${getValues('optimizeSchedule')} Appts available`
                    : 'Off'
                }
                isEdit
                isOptional
                isOptimizeScheduleField
                isTooltipDisplayed={isEnabled}
              >
                <div className="min-w-[38rem]">
                  <OptimizedScheduleToggle
                    control={control}
                    onChange={onChangeOptimizedSchedule}
                    isEnabled={isEnabled}
                  />
                </div>
              </ProfileInfo>

              <ProfileInfo
                title={'Bio'}
                data={getValues('bio')}
                isEdit
                isOptional
              >
                <div className="h-[23.7rem] min-w-[38rem] relative">
                  <textarea
                    placeholder="Input Bio"
                    className="w-full resize-none h-full text-14 py-1.3 pl-1.6 pr-4.5 rounded-[1rem] border-[0.5px] placeholder:text-mid-grey shadow-primary border-lightest-grey hover:border-magenta hover:shadow-input-active focus:border-magenta focus:shadow-input-active focus-visible:border-magenta focus-visible:shadow-input-active scrollbar !outline-none !ring-transparent"
                    defaultValue={getValues('bio')}
                    {...register('bio')}
                  />
                </div>
              </ProfileInfo>
            </div>
            <div>
              <ImageUpload
                isEdit
                avatar={watch('avatar')}
                onRemoveAvatar={() => {
                  setValue('avatar', '');
                  setValue('uploadedFile', null);
                }}
                setValue={setValue}
                avatarPlaceholder={NoAvatarPractitioner}
              />
            </div>
          </div>
        </div>
        <div className="flex h-[6.6rem] bg-white gap-x-1.6 items-center justify-end">
          <CommonButton onClick={onClose} variant="secondary">
            Cancel
          </CommonButton>
          <CommonButton
            isLoading={formState.isSubmitting}
            disabled={!formState.isDirty}
            onClick={handleSubmit(onSubmit)}
          >
            Create
          </CommonButton>
        </div>
      </div>
    </DefaultModal>
  );
};

export default CreatePractitionerModal;
