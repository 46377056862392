import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';
import { loadSelectedClinicId } from 'utils/storage';
import { IPractitioner } from 'interfaces/practitioners';
import { IService } from 'interfaces/services';
import { Operatory } from 'interfaces/operatory';
import { IPatient } from 'interfaces/patients';
import { getErrorReason } from 'utils/common';
export interface IPMSAppointment {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  status: string;
  externalId: string;
  appointmentDate: string;
  timezone: string;
  startTime: string;
  duration: number;
  pmsType: string;
  errorReason: string;
  prevErrorReason: string | null;
  unavailable: boolean;
  patient: IPatient | null;
  operatory: Operatory;
  doctor: IPractitioner | null;
  service: IService | null;
  endTime: string;
  isLinkedAppointment: boolean;
  isSplitScheduling: boolean;
  linkedAppointmentId: string;
  linkedAppointment: IPMSAppointment | null;
}

interface IUsePMSAppointmentParams {
  date: string;
  selectedView: string;
  shouldRun: boolean;
}

const usePMSAppointment = ({
  date,
  selectedView,
  shouldRun,
}: IUsePMSAppointmentParams) => {
  const clinicId = loadSelectedClinicId();
  const { data, error, mutate } = useSWR(
    shouldRun ? ['/pms/appointments', clinicId, date, selectedView] : null,
    async (...resource) => {
      const [url] = resource;
      const response = await axiosInstance.get<IPMSAppointment[]>(url, {
        params: {
          date,
          view: selectedView,
        },
      });

      const result = response.data.map((appointment) => {
        if (!appointment.isSplitScheduling) {
          return appointment;
        }

        const updatedAppointment = { ...appointment };

        const foundApptLinked = response.data.find(
          (item) => item.linkedAppointmentId === appointment.id
        );

        if (foundApptLinked || appointment.isLinkedAppointment) {
          updatedAppointment.linkedAppointment = foundApptLinked
            ? foundApptLinked
            : response.data.find(
                (item) => item.id === appointment.linkedAppointmentId
              ) || null;

          updatedAppointment.errorReason = getErrorReason(updatedAppointment);
        }

        return updatedAppointment;
      });

      return result;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default usePMSAppointment;
