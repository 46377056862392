import axiosInstance from 'apis/axiosInstance';
import useSWR from 'swr';
import { loadSelectedClinicId } from 'utils/storage';

interface IUseClinicrWeeklyTimeSlotParams {
  serviceId: string;
  dates: [string, string];
  currentDate: string;
  timeBlocks: number[];
  timezone: string;
  shouldRun?: boolean;
}

const useClinicWeeklyTimeSlot = ({
  dates,
  timeBlocks,
  currentDate,
  shouldRun = true,
  ...rest
}: IUseClinicrWeeklyTimeSlotParams) => {
  const clinicId = loadSelectedClinicId();
  const query = new URLSearchParams(rest as any);

  const convertTimeBlocksToCorrectFormat = timeBlocks.reduce(
    (prev, curr) => prev + `&timeBlocks[]=${curr}`,
    ''
  );

  const url = `/clinics/${clinicId}/appointments/weekly-timeslots?id=${clinicId}&${query}&dates[]=${dates[0]}&dates[]=${dates[1]}${convertTimeBlocksToCorrectFormat}`;

  const { data, error, mutate } = useSWR(
    shouldRun ? url : null,
    async (url: string) => {
      const response = await axiosInstance.get(url, {
        params: {
          // currentDate is not stable
          currentDate,
        },
      });
      return response.data;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useClinicWeeklyTimeSlot;
