import { Listbox } from '@headlessui/react';
import { range } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';

import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';
import { MAX_DOCTOR_DURATION, MAX_HYGIENIST_DURATION } from 'utils/constants';

interface DurationSectionProps {
  control: any;
  isSplitScheduling: boolean;
  hygienistDuration?: number | null;
  doctorDuration?: number | null;
  timeInterval: number;
}

const DurationSection: FC<DurationSectionProps> = ({
  control,
  isSplitScheduling,
  doctorDuration,
  hygienistDuration,
  timeInterval,
}) => {
  const [isOverflow, setIsOverflow] = useState(false);

  const dropdownRef = useRef<HTMLButtonElement | null>(null);
  const getHygienistDuration = () => {
    const limit = MAX_HYGIENIST_DURATION + timeInterval;
    const start = timeInterval;
    if ((!hygienistDuration && !doctorDuration) || !doctorDuration) {
      return range(start, limit, timeInterval).map((value) => value);
    }

    const diff = limit - doctorDuration;

    return range(start, diff, timeInterval).map((value) => value);
  };

  const getDoctorDuration = () => {
    const start = 0;

    const limit = MAX_DOCTOR_DURATION + timeInterval;
    const max = MAX_HYGIENIST_DURATION + timeInterval;

    if ((!hygienistDuration && !doctorDuration) || !hygienistDuration) {
      return range(start, limit, timeInterval).map((value) => value);
    }

    const diff = max - hygienistDuration;

    return diff > 0
      ? range(start, diff, timeInterval).map((value) => value)
      : [0];
  };

  useEffect(() => {
    const element = dropdownRef.current;
    if (!element) return;

    const position = element.getBoundingClientRect();

    const contentHeight = 140;
    const isOverflow =
      position.top + element.offsetHeight + contentHeight >= window.innerHeight;
    setIsOverflow(isOverflow);
  }, []);

  return (
    <div className="flex items-center gap-x-2">
      <div className="flex flex-col gap-y-0.8 flex-1">
        <h3 className="text-darkest-grey text-16 leading-[2.4rem] font-bold">
          Hygienist Duration
        </h3>
        <Controller
          name={'hygienistDuration'}
          control={control}
          rules={{
            validate: (value) => {
              if (!isSplitScheduling) {
                return true;
              }

              return value !== null;
            },
          }}
          render={({ field }) => (
            <Listbox {...field} disabled={!isSplitScheduling}>
              {({ open }) => {
                return (
                  <div className="relative">
                    <Listbox.Button
                      className={`relative w-full h-[4.8rem] pl-1.6 cursor-pointer rounded-[1rem] bg-white border-[0.5px] border-light-grey text-left shadow-primary hover:border-magenta hover:shadow-input-active outline-none ${
                        open ? 'border-magenta shadow-input-active' : ''
                      }`}
                      ref={dropdownRef}
                    >
                      <span
                        className={`block pr-3 ${
                          field.value && isSplitScheduling
                            ? 'text-darkest-grey'
                            : 'text-mid-grey'
                        } `}
                      >
                        {field.value
                          ? getHygienistDuration().includes(field.value)
                            ? `${field.value} minutes`
                            : ''
                          : 'Select Duration'}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <DropdownIcon className="w-1.4 h-1.4 " />
                      </span>
                    </Listbox.Button>
                    <Listbox.Options
                      className={`absolute mt-0.5 max-h-14 scrollbar w-full overflow-auto rounded-[0.8rem] bg-white py-1.5 text-base shadow-primary flex flex-col gap-y-1.5 ${
                        isOverflow ? '-translate-y-[calc(100%+5.8rem)]' : ''
                      }`}
                    >
                      {getHygienistDuration().map((value) => (
                        <Listbox.Option
                          key={value}
                          className="relative cursor-default select-none pl-1.6"
                          value={value}
                        >
                          {({ selected }) => (
                            <span
                              className={`block truncate cursor-pointer hover:text-magenta ${
                                selected ? 'text-magenta' : ''
                              }`}
                            >
                              {value} minutes
                            </span>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </div>
                );
              }}
            </Listbox>
          )}
        />
      </div>
      <div className="flex flex-col gap-y-0.8 flex-1">
        <h3 className="text-darkest-grey text-16 leading-[2.4rem] font-bold">
          Doctor Duration
        </h3>
        <Controller
          name={'doctorDuration'}
          control={control}
          rules={{
            validate: (value) => {
              if (!isSplitScheduling) {
                return true;
              }

              return value !== null;
            },
          }}
          render={({ field }) => (
            <Listbox {...field} disabled={!isSplitScheduling}>
              {({ open }) => {
                return (
                  <div className="relative">
                    <Listbox.Button
                      className={`relative w-full h-[4.8rem] pl-1.6 cursor-pointer rounded-[1rem] bg-white border-[0.5px] border-light-grey text-left shadow-primary hover:border-magenta hover:shadow-input-active outline-none ${
                        open ? 'border-magenta shadow-input-active' : ''
                      }`}
                    >
                      <span
                        className={`block pr-3 ${
                          field.value !== null && isSplitScheduling
                            ? 'text-darkest-grey'
                            : 'text-mid-grey'
                        } `}
                      >
                        {field.value !== null
                          ? getDoctorDuration().includes(field.value)
                            ? `${
                                field.value === 0
                                  ? 'Working Day'
                                  : `${field.value} minutes`
                              }`
                            : ''
                          : 'Select Duration'}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <DropdownIcon className="w-1.4 h-1.4" />
                      </span>
                    </Listbox.Button>
                    <Listbox.Options
                      className={`absolute mt-0.5 max-h-14 scrollbar w-full overflow-auto rounded-[0.8rem] bg-white py-1.5 text-base shadow-primary flex flex-col gap-y-1.5 ${
                        isOverflow ? '-translate-y-[calc(100%+5.8rem)]' : ''
                      }`}
                    >
                      {getDoctorDuration().map((value) => (
                        <Listbox.Option
                          key={value}
                          className="relative cursor-default select-none pl-1.6"
                          value={value}
                        >
                          {({ selected }) => (
                            <span
                              className={`block truncate cursor-pointer hover:text-magenta ${
                                selected ? 'text-magenta' : ''
                              }`}
                            >
                              {value === 0 ? `Working Day` : `${value} minutes`}
                            </span>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </div>
                );
              }}
            </Listbox>
          )}
        />
      </div>
    </div>
  );
};

export default DurationSection;
