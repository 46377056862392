import React from 'react';
import MultipleSelect, { Option } from '../MultipleSelect/MultipleSelect';
import { sortBy } from 'lodash';
import {
  Controller,
  FieldValues,
  RegisterOptions,
  UseFormSetValue,
} from 'react-hook-form';
import useSpecialty from 'hooks/useSpecialty';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { SERVICE_NAME, SPECIALTY_NAME } from 'utils/constants';

interface IProps {
  control: any;
  placeholder?: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  specialties: Option[];
  setValue: UseFormSetValue<any>;
  services: Option[];
}

const SpecialtyDropdown = (props: IProps) => {
  const { data: specialties, isLoading } = useSpecialty();

  const specialtyOptions = specialties?.map((specialty) => ({
    value: specialty.id,
    label: specialty.name,
  }));

  const handleOnChange = (
    value: Option[],
    newValue: Option[],
    onChange: (...event: any[]) => void
  ) => {
    const isHyginiestRemoved =
      value.some((item: Option) => item.label === SPECIALTY_NAME.HYGIENIST) &&
      !newValue.some((item: Option) => item.label === SPECIALTY_NAME.HYGIENIST);

    if (isHyginiestRemoved) {
      const excludeExamCleaning = props.services.filter(
        (service) => !service.label.includes(SERVICE_NAME.EXAM_CLEANING)
      );

      props.setValue('services', excludeExamCleaning, {
        shouldValidate: true,
      });
    }

    onChange(newValue);
  };

  return (
    <Controller
      {...props}
      name="specialties"
      render={({ field, fieldState: { error } }) => {
        const { onChange, value } = field;

        return (
          <>
            <MultipleSelect
              isError={!!error?.message}
              key={`${isLoading}`}
              isLoading={isLoading}
              placeholder={'Select Specialty'}
              options={sortBy(specialtyOptions, 'label')}
              value={isLoading ? [] : value}
              onChange={(newValue: any) =>
                handleOnChange(value, newValue, onChange)
              }
            />
            {error?.message && (
              <div className={`flex items-center gap-x-1 mt-1`}>
                <WarningIcon />
                <span className="text-12 text-red-01">{error.message}</span>
              </div>
            )}
          </>
        );
      }}
    />
  );
};

export default SpecialtyDropdown;
