import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';
import { loadSelectedClinicId } from 'utils/storage';
import { IService } from 'interfaces/services';

interface IUseClinicServiceParams {
  sort?: string;
  sortBy?: string;
}

export interface IUseClinicServiceResponse {
  metadata: {
    total: number;
  };
  data: IService[];
}

const useClinicService = (params?: IUseClinicServiceParams) => {
  const query = new URLSearchParams(params as any).toString();

  const clinicId = loadSelectedClinicId();
  const url = `/clinics/${clinicId}/services?${query}`;

  const { data, error, mutate } = useSWR(
    [url, clinicId],
    async (...resource) => {
      const [url] = resource;
      const response = await axiosInstance.get<IUseClinicServiceResponse>(url);
      return response.data;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useClinicService;
