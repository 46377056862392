import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import CommonButton from 'components/CommonButton/CommonButton';
import { renderToast } from 'components/Toast/Toast';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { IAppointmentStatus } from 'hooks/useAppointment';
import { loadAuthToken } from 'utils/storage';
import axiosInstance from 'apis/axiosInstance';
import { IAppointmentDetail } from 'interfaces/appointments';
import { PMS_TYPE } from 'utils/constants';
import { convertDurationForFE } from 'utils/convertDuration';

const HEADERS = [
  { label: 'Appointment Date & Time', key: 'appointmentDate' },
  { label: 'Patient', key: 'patientName' },
  { label: 'Practice', key: 'clinicName' },
  { label: 'Practitioner', key: 'practitionerName' },
  { label: 'Service', key: 'serviceName' },
  { label: 'Requested Time', key: 'createdAt' },
  { label: 'Error Type', key: 'errorType' },
];

const CSVExport = ({
  appointmentStatus,
  clinicId,
  dateRange,
  sort,
  sortBy,
  isDataLoading,
}: {
  appointmentStatus: IAppointmentStatus;
  clinicId: string | null;
  dateRange: {
    fromDate: string;
    toDate: string;
  };
  sort: 'asc' | 'desc';
  sortBy: string;
  isDataLoading: boolean;
}) => {
  const csvRef = useRef<any>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [csvData, setCsvData] = useState([]);

  const normalizePractitionerName = (item: IAppointmentDetail) => {
    if (item.linkedAppointment) {
      const linkedAppointment = item.linkedAppointment;

      return `${item.doctor.name} (${convertDurationForFE(
        item.duration || 0
      )} mins) \n${linkedAppointment.doctor.name} (${convertDurationForFE(
        linkedAppointment.duration || 0
      )} mins)`;
    }

    if (item.isSplitScheduling && item.errorReason) {
      return `${item.doctor.name} (${convertDurationForFE(
        item.duration || 0
      )} mins) \nDoctor is unavailable`;
    }

    if (item.isSplitScheduling && !item.errorReason) {
      return `${item.doctor.name} (${convertDurationForFE(
        item.duration || 0
      )} mins) \nDoctor's working day`;
    }

    return item.doctor.name;
  };

  const getAppointment = async () => {
    setIsLoading(true);

    try {
      const query = new URLSearchParams({
        startDate: moment(dateRange.fromDate, 'YYYY-MM-DD')
          .startOf('days')
          .utc(true)
          .format(),
        endDate: moment(dateRange.toDate, 'YYYY-MM-DD')
          .endOf('days')
          .utc(true)
          .format(),
        sort,
        sortBy,
        ...(appointmentStatus !== 'all' && { status: appointmentStatus }),
        pmsType: PMS_TYPE.FIRST_IN,
      }).toString();

      const url = `/appointments?${query}`;
      const { data: axiosAppointmentResponse } = await axiosInstance.get(url, {
        headers: {
          Authorization: `JWT ${loadAuthToken()?.accessToken}`,
          ...(clinicId && { 'X-ClinicId': clinicId }),
        },
      });

      const csvData = axiosAppointmentResponse.data.map(
        (item: IAppointmentDetail) => {
          return {
            appointmentDate: `${moment(
              item.appointmentDate,
              'YYYY-MM-DD'
            ).format('MMM DD, YYYY')} ${moment(
              item.startTime,
              'HH:mm:ss'
            ).format('hh:mm A')}`,
            clinicName: item.clinic!.name,
            patientName: item.patient?.name || '',
            practitionerName: normalizePractitionerName(item),
            serviceName: item.service?.name,
            createdAt: moment(item.createdAt).format('MMM DD, YYYY hh:mm A'),
            errorType: item.errorReason || '',
          };
        }
      );

      setCsvData(csvData);
      if (csvRef.current) {
        csvRef.current.link.click();
      }
    } catch (error) {
      renderToast({
        type: 'error',
        message: 'An error has occurred. Please try again.',
      });
    }

    setIsLoading(false);
  };

  return (
    <>
      <CommonButton
        variant="secondary"
        className="!min-h-[35px] h-3.5"
        isLoading={isLoading}
        disabled={isDataLoading}
        onClick={getAppointment}
      >
        <DownloadIcon
          className={isDataLoading ? 'all-child:all-child:fill-current' : ''}
        />
        <span className="ml-1">Export</span>
      </CommonButton>
      <CSVLink
        className="hidden"
        ref={csvRef}
        filename={'appointments.csv'}
        data={csvData}
        headers={HEADERS}
      />
    </>
  );
};

export default CSVExport;
