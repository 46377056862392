import { FC } from 'react';

import TextField from 'components/TextField/TextField';

interface GoogleTagManagerProps {
  control: any;
}

const GoogleTagManager: FC<GoogleTagManagerProps> = ({ control }) => {
  return (
    <section>
      <p className="font-bold mb-1">Google Tag Manager ID</p>
      <TextField
        id={'googleTagManagerId'}
        placeholder="Input Google Tag Manager ID"
        control={control}
      />
    </section>
  );
};

export default GoogleTagManager;
